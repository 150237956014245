import React from "react";
import { CustomHeaders } from "./CustomHeaders";
import { storeUser, storeJobPosition } from "../../../_reducers/reducer.user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import API from "../../../_api";
import Loader from "../../Loader";
class JobPosition extends React.Component {
  async componentDidMount() {
    //let { user: { student: { program } } } = this.props;
    let appliedList;
    try {
      appliedList = await API.Candidate.GetAppliedList(this.props);

      if (appliedList.status === "1") {
        toast.error(appliedList.message);
        appliedList = [];
        return;
      }
    } catch (error) {
      appliedList = [];
      toast.error(error.message);
      return;
    }

    API.Login.GetJobPosition(this.props.subscription.subscription).then(
      (res) => {
        if (res.status !== "1") {
          this.setState({
            joblist: res.jobList.map((item) => {
              let selectedrec = appliedList.some(
                (rec) => item.jobPosUID === rec.jobPosUID
              );
              if (selectedrec) {
                item.applied = true;
                return item;
              } else {
                return item;
              }
            }),
            noJobs: res.length < 1,
          });
        } else {
          toast("There is no job position.", { autoClose: true });
        }
      }
    );
  }

  onApplyClick = (obj, ev) => {
    let {
      user: { password },
    } = this.props;
    this.setState({ loading: true });
    API.Candidate.Profile(password).then((res) => {
      this.setState({ loading: false });
      if (res.status !== "1") {
        let { user } = this.props;
        let modUser = {
          ...user,
          password: {
            ...user.password,
            ...res,
          },
        };
        this.props.storeUser(modUser);
        this.props.storeJobPosition(obj);
        this.props.history.push("/applypage/apply");
      } else {
        // toast.error(res.message);
        this.setState({ loading: false });
      }
    });
  };
  state = {
    joblist: [],
  };
  render() {
    let {
      history: { location },
    } = this.props;
    let { joblist, loading, noJobs } = this.state;
    return (
      <section id="content">
        <CustomHeaders {...location} />
        <Loader text="Please wait..." loading={loading} />
        <ToastContainer style={{ marginTop: 50 }} />
        <div className="container">
          <section id="content">
            <div id="university" className="tab-content row">
              <div className="col-md-12">
                <div className="content-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h2>Jobs</h2>
                  </div>
                  <div>
                    {/* className="content-height" */}
                    {joblist.map((data, ind) => (
                      <React.Fragment>
                        <div
                          style={{
                            margin: 5,
                            padding: 1,
                            width: "100%",
                            height: "1px",
                            background: "rgba(0, 0, 0, 0.1)",
                          }}
                        />
                        <div
                          className="row"
                          style={{ overflowY: "auto", maxheight: 200 }}
                        >
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="lib-row lib-header">
                                  <strong> {data.jobTitle}</strong>
                                  <div className="lib-header-seperator"></div>
                                </div>
                                <div className="lib-row lib-desc">
                                  {/* {data.description} */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: data.description,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          disabled={data.applied}
                          id="btt_next"
                          type="button"
                          className="btn"
                          onClick={(ev) => this.onApplyClick(data, ev)}
                        >
                          <span>Apply</span>
                        </button>

                        {/* <ButtonLogin id="btn_apply" style={{display:"none"}} title="Sign In" goto={(path, from) => {
                                                        this.props.history.push({ pathname: path, state: { from } })
                                                    }}
                                                        newlogin='0' status="student" /> */}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                  {noJobs &&
                    "No Jobs available at present. Please check out after some time."}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeJobPosition,
      storeUser,
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  user: state.user.user,
  subscription: state.user.subscription,
});
export default connect(mapStateToProps, mapDispatchToProps)(JobPosition);
