import { formDataConsole, getSignature, DEV_URL, devLog } from "../../_helper/helper.methods";
var ip = require("ip");

export const List = (user, formData) => {

    let signature = ip.address().toLowerCase() + user.userUID.toLowerCase() + user.sessionToken;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append("ActionBy", user.userUID);
    formData.append('DeviceIP', ip.address());
    formDataConsole(formData);
    //return fetch(`/${DEV_URL}v1/master/package/list`, {
    let url = 'v1/master/package/list';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// package list response", res)
            return res;
        })
        .catch((err) => { devLog(err) });
}
