import React from "react";
import { CustomHeaders } from "./CustomHeaders";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import API from "../../../_api";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../../Validators/TextValidator";
import Loader from "../../Loader";
import Modal from "react-responsive-modal";

class ChangePassword extends React.Component {
  state = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    countDown: 5,
    alertModal: false,
    loading: false,
  };
  onChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  setTimeInverval = () => {
    let countDown = 5;
    let timer = setInterval(() => {
      if (countDown === 0) {
        clearInterval(timer);
        let { password } = this.props.user;
        window.location.href = `/home/${password.subscription}`;
      } else {
        countDown = countDown - 1;
        this.setState({ countDown });
      }
    }, 1000);
  };
  onChangePassword = (e) => {
    let {
      user: { password },
    } = this.props;
    this.setState({ loading: true });
    let formData = new FormData(e.target);
    formData.append("ProfileUID", password.profileUID);
    API.Login.changePassword(password, formData).then((res) => {
      this.setState({ loading: false });
      if (res.status !== "1") {
        this.setTimeInverval();
        this.setState({
          alertModal: true,
        });
      } else {
        this.setState({ loading: false });
        toast.error(res.message, { autoClose: true });
      }
    });
  };
  render() {
    let {
      history: { location },
    } = this.props;
    let {
      countDown,
      alertModal,
      loading,
      newPassword,
      oldPassword,
      confirmPassword,
    } = this.state;
    return (
      <section id="content">
        <CustomHeaders {...location} />
        <Loader loading={loading} text="please Wait..." />
        <ToastContainer style={{ marginTop: 50 }} />
        <Modal
          center
          open={alertModal}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          showCloseIcon={false}
        >
          <div>
            <h4>Password Changed Successfully</h4>
            <p>you will be redirected to login in </p>
            <h3 style={{ textAlign: "center" }}>{countDown}</h3>
          </div>
        </Modal>
        <div className="content-tab" style={{ padding: 10 }}>
          <h4>Change Password</h4>
          <div className="content-height" style={{ height: "78vh" }}>
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <ValidatorForm onSubmit={this.onChangePassword}>
                  <TextValidator
                    onChange={this.onChange}
                    type="password"
                    name="oldPassword"
                    value={oldPassword}
                    required
                    placeholder="Current Password"
                  />
                  <TextValidator
                    onChange={this.onChange}
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    required
                    placeholder="New Password"
                  />
                  <TextValidator
                    onChange={this.onChange}
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    pattern={`^${newPassword}`}
                    title="Old and new password should be same"
                    required
                    placeholder="Confirm Password"
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      type="submit"
                      style={{ width: 100 }}
                      className="btn-next"
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </ValidatorForm>
              </div>
              <div className="col-md-3" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
const mapStateToProps = (state) => ({
  user: state.user.user,
  subscription: state.user.subscription,
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
