import React from 'react'
import { Route } from 'react-router-dom'

import ApplyPage from "../pages/ApplyPage";
import WelcomeView from '../components/Apply/welcomeview';


class Pages extends React.Component {

    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <main className="animated fadeIn apply">
                    <div className="container">
                        <Route path={`${match.path}`} component={WelcomeView} />
                        <Route exact path={`${match.path}/apply`} component={ApplyPage} />
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default Pages;