import React from 'react';
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'

import store, { history } from './_helper/helper.store'
import {unregister} from './registerServiceWorker';
import App from './components/App';

require('bootstrap');
require('./style.css');
require('./assets/styles/components/select2.css')

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <React.Fragment>              
                <App />                
            </React.Fragment> 
        </ConnectedRouter>
    </Provider>,
    document.getElementById('app'));
unregister();
