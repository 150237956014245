import React from "react";
import { ValidatorComponent } from 'react-form-validator-core';

class CheckBoxValidator extends ValidatorComponent {
    render() {
        const { invalidMessage, text, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        return (
            <div className={"checkbox"}>
                <label><input
                    onInput={(e) => e.target.setCustomValidity("")}
                    onInvalid={(e) => e.target.setCustomValidity(invalidMessage)}
                    style={{ fontSize: 12 }}
                    type='checkbox'
                    {...rest}
                    ref={(r) => { this.input = r; }}
                />{"  " + text}</label>
            </div>)
    }
}

export default CheckBoxValidator;
