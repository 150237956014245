// IMPORT PACKAGE REFERENCES
/*eslint-disable no-unused-vars*/
import React from 'react';
import { ApplyHeader, ApplyContent } from '../components/Apply';

// COMPONENT

const ApplyPage = (props) => (
    <ApplyContent {...props} />
);

export default ApplyPage;