
import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import Select2 from 'react-select2-wrapper';

class SelectValidator extends ValidatorComponent {
    render() {
        const { className, add, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        return (
            <div className={className} style={{ marginBottom: "1em" }}>
                <Select2
                    className="form-control"
                    {...rest}
                    ref={(r) => { this.Select2 = r; }}
                />
                {/* {data &&
                    <select style={{ fontSize: 12, height: 32 }} {...rest} className="form-control" required>
                        <option value="" disabled selected hidden>{placeholder}</option>
                        {typeof data[0] !== "string" ?
                            <React.Fragment>
                                {data.map((dt, t) => (
                                    <option key={t} value={dt.text}>{dt.text}</option>
                                ))}
                            </React.Fragment>
                            : <React.Fragment>
                                {data.map((dt, t) => (
                                    <option key={t} value={dt}>{dt}</option>
                                ))};
                </React.Fragment>}

                    </select>
                } */}
                {this.errorText()}
            </div >

        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red', fontSize: 12 }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}
export default SelectValidator;