import { formDataConsole, getSignature, DEV_URL, devLog/*, OPEN_TOKEN*/ } from "../../_helper/helper.methods";
var ip = require("ip");


export const GetJobPosition = (subscription) => {
    const formData = new FormData();
    let signature = subscription;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', subscription);
    formData.append("ActionBy", '');
    formData.append('DeviceIP', ip.address());

    let url = '/api/hrms/rms/candidate/job/list';

    formDataConsole(formData, url);

    devLog('url', url);

    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// Job list response", res)
            return res;
        })
        .catch((err) => { devLog(err) });
}

export const Email = (formData, subscription) => {
    formData.append('Subscription', subscription);
    formData.append('DeviceIP', ip.address());

    let url = '/api/hrms/rms/candidate/email';

    formDataConsole(formData, url);
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// email response", res)
            return res;
        }).catch((err) => { devLog(err) });
}

export const Password = (formData, subscription) => {
    let signature = formData.get('userId') + formData.get('userPassword') + formData.get('token');
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Subscription', subscription);
    formData.append('Signature', signature);
    formData.append('DeviceIP', ip.address());

    let url = '/api/hrms/rms/candidate/password';

    formDataConsole(formData, url);
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// password response", res)
            return res;
        }).catch((err) => { devLog(err) });
}
export const changePassword = (user, formData) => {
    let signature = user.subscription.toLowerCase() + user.profileUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Subscription', user.subscription);
    formData.append('Signature', signature);
    formData.append('ActionBy', user.profileUID);
    formData.append('DeviceIP', ip.address());
    // formDataConsole(formData, 'change password');
    let url = '/api/hrms/rms/candidate/change';

    formDataConsole(formData, url);
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// change password response", res)
            return res;
        }).catch((err) => { devLog(err) });
}

export const forgetPassword = (token, formData) => {
    let signature = formData.get('subscription').toLowerCase() + formData.get('profileUID').toLowerCase() + token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('ActionBy', formData.get('profileUID'));
    formData.append('DeviceIP', ip.address());
    // formDataConsole(formData, 'forget password');
    let url = '/api/hrms/rms/candidate/forget';

    formDataConsole(formData, url);
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// forget  password response", res)
            return res;
        }).catch((err) => { devLog(err) });
}

export const SignUp = (formData, subscription) => {
    let signature = formData.get('email').toLowerCase();

    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Subscription', subscription);
    formData.append('Signature', signature);
    formData.append("ActionBy", '00000000-0000-0000-0000-000000000000');
    formData.append('DeviceIP', ip.address());

    let url = '/api/hrms/rms/candidate/signup';

    formDataConsole(formData, url);
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// signup", res)
            return res;
        }).catch((err) => { devLog(err) });
}

