// IMPORT PACKAGE REFERENCES

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
/*import ButtonLogin from './ButtonLogin';*/
import PropTypes from "prop-types";

//redux
import { removeUser } from "../../_reducers/reducer.user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { errorNotification } from "../../_reducers/reducer.notification";
import { devLog } from "../../_helper/helper.methods";
// COMPONENT

class HeaderLogin extends Component {
    state = {
        username: "",
        profilePicture: this.props.profilePicture !== null && this.props.profilePicture !== undefined ? this.props.profilePicture : this.props.user.password.profile[0].photoURL,
    };

    componentDidUpdate() {
        if (this.state.profilePicture !== this.props.profilePicture && this.props.profilePicture !== undefined) {
            this.setState({
                profilePicture: this.props.profilePicture,
            });
            devLog(this.state.profilePicture)
        }
    }

    signout = () => {
        let { user, history } = this.props;
        this.props.removeUser();
        history.push(`/home/${user.password.subscription}`);
    };
    render() {
        let {
            user: { password },
        } = this.props;
        const {
            profilePicture
        } = this.state;
        // if(password !== undefined){
        //     this.setState({
        //         username:password ? '' : password.firstname
        //     })
        // }
        let subscription = this.props.subscription;
        let defaultimage = "/images/user.png";
        return (
            <header className="nav-bar">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        {/*eslint-disable no-script-url*/}
                        <NavLink to="/applypage/welcome" className="logo-text navbar-brand">
                            <span>
                                <img
                                    style={{ height: 35 }}
                                    alt="Sarvam"
                                    src={"/images/sarvamnamewhite.png"}
                                />
                            </span>
                        </NavLink>
                        <div className="pull-right">
                            <img
                                alt=""
                                src={
                                    subscription
                                        ? subscription.logo
                                            ? subscription.logo
                                            : ""
                                        : ""
                                }
                                style={{ maxWidth: "80px" }}
                            />
                        </div>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <div className="col-md-4 offset-md-8">
                                <ul className="nav-menu navbar-nav">
                                    <li className="nav-item">
                                        <div className="dropdown">
                                            <a
                                                style={{ display: "flex", alignItems: "center" }}
                                                className="topbar-home"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: 18,
                                                        marginRight: 15,
                                                        color: "white",
                                                    }}
                                                >
                                                    {password ? password.profile[0].firstName : ""}
                                                </span>
                                                <span>
                                                    <React.Fragment>
                                                        <img
                                                            style={{
                                                                height: 30,
                                                                width: 30,
                                                                borderRadius: "50%",
                                                            }}
                                                            alt="profilepicture"
                                                            src={profilePicture !== null && profilePicture !== '' ? profilePicture : defaultimage}

                                                        />
                                                    </React.Fragment>

                                                </span>
                                            </a>
                                            <div
                                                style={{ alignItems: "center", width: 250 }}
                                                className="dropdown-menu dropdown-menu-right"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <a
                                                    onClick={() =>
                                                        this.props.history.push("/applypage/changepassword")
                                                    }
                                                    className="dropdown-item"
                                                    style={{
                                                        alignItems: "center",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <span>Change Password</span>
                                                    <span>
                                                        <img
                                                            style={{ height: 20, width: 20 }}
                                                            alt="Settings"
                                                            src={require("../../assets/images/settings.png")}
                                                        />
                                                    </span>
                                                </a>
                                                <a
                                                    onClick={this.signout}
                                                    className="dropdown-item"
                                                    style={{
                                                        alignItems: "center",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <span>Sign out</span>
                                                    <span>
                                                        <img
                                                            style={{ height: 20, width: 20 }}
                                                            alt="Settings"
                                                            src={require("../../assets/images/signout_black.png")}
                                                        />
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    {/* <li className="nav-item">
                                        <div class="language">
                                            <select value={getLanguage()} onChange={this.changeLanguage} id="lang">
                                                <option title="ID" status="id" value="id">ID</option>
                                                <option title="EN" status="en" value="en">EN</option>
                                            </select>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

HeaderLogin.propTypes = {
    title: PropTypes.string,
    status: PropTypes.string,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            errorNotification,
            removeUser,
        },
        dispatch
    );
const mapStateToProps = (state) => ({
    user: state.user.user,
    subscription: state.user.subscription,
    profilePicture: state.user.profilePicture,
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogin);
