
import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';

class TextValidator extends ValidatorComponent {
    render() {
        const { className, children, label, req, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        //let required = validators[0] === 'required';
        return (
            // <div className="form-group" style={label ? {} : { margin: 0 }}>                
            <div className={className}>
                <input
                    style={{ fontSize: 12 }}
                    className="form-control"
                    {...rest}
                    ref={(r) => { this.input = r; }}
                />
                {children}
                {this.errorText()}
            </div>
            // </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red', fontSize: 12 }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default TextValidator;