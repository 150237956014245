import React from 'react';
import { NavLink } from 'react-router-dom';

const InitialPage = () => {
    return (
        <div>
            <header className="nav-bar">
                <nav className="navbar navbar-expand-lg navbar-light" style={{ height: 50 }}>
                    <div className="container-fluid">
                        <NavLink to="/" className="logo-text navbar-brand"><img style={{ height: 35 }} alt="Sarvam" src={'/images/sarvamnamewhite.png'} /></NavLink>
                    </div>
                </nav>
            </header>
            <div style={{ textAlign: 'center', margin: 50, height: 'calc(100vh - 156px)' }}>
                <h1>You are trying to access site with invalid URL<br /> Please check it out</h1>

            </div>
        </div>
    )
};

export default InitialPage;