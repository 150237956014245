import React from "react";
import { CustomHeaders } from "./CustomHeaders";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import API from "../../../_api";

import { storeMaster } from "../../../_reducers/reducer.user";
import { devLog } from "../../../_helper/helper.methods";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

class WelcomeBack extends React.Component {
  candidateApplyJSON = () => {
    let {
      password: { userUID },
    } = this.props.user;
    return {
      profile: {
        uid: this.props.user.profileUID,
        firstName: null,
        photoURL: null,
      },
    };
  };

  constructor(props) {
    super(props);
    let {
      password: { userUID },
    } = props.user;
    this.state = {
      candidate: this.candidateApplyJSON(),
    };
  }

  componentDidMount() {
    this.loadModuleMaster();
    this.setExitingValues();
    let {
      user: { from },
      jobposition,
    } = this.props;
  }

  loadModuleMaster = () => {
    API.Master.List(this.props.user, this.props.subscription.subscription).then(
      (res) => {
        if (res.status !== "1") {
          let mlist = {
            ...this.props.master,
            modulemaster: {
              ...res,
            },
          };
          devLog("mliust", mlist);
          this.props.storeMaster(mlist);
          this.setExitingValues();
        } else {
          // toast.error(res.message, { autoClose: true });
        }
      }
    );
  };

  setExitingValues = async () => {
    let {
      user: { password },
    } = this.props;

    let srec = this.state.candidate;

    if (password.profile.length > 0) {
      srec.profile = password.profile[0];
      if (srec.profile.photoURL || this.props.profilePicture) {
        let res = await API.Master.AccessBlob(
          password, this.props.profilePicture !== undefined && this.props.profilePicture !== null
          ? this.props.profilePicture
          : srec.profile.photoURL);
        if (res.status !== "1") {
          this.setState({
            profilePicture: res.message,
          });
        } else {
          // toast.error(res.message);
        }
      }
      else {
        this.setState({
          profilePicture: "/images/profile-default.jpg",
        });
      }
    }
  };

  render() {
    let {
      user: { password, from },
      history: { location },

    } = this.props;
    const {
      profilePicture
    } = this.state;
    let defaultimage = "/images/profile-default.jpg";

    return (
      <React.Fragment>
        <section id="content">
          <CustomHeaders {...location} />

          <div id="welcomeBack" className="tab-content">
            <div
              style={{
                marginTop: 15,
                padding: "10px 10px 10px 50px",
              }}
            >
              <div className="row">
                <h2
                  style={{
                    fontSize: 26,
                    paddingBottom: 15,
                  }}
                >
                  Welcome {from === "signin" && "Back"}
                </h2>
                <h2
                  style={{
                    fontSize: 26,
                    paddingBottom: 15,
                    paddingLeft: 10,
                  }}
                >
                  ' {password.profile[0].firstName} ' !
                </h2>
              </div>
              <div className="content-height">
                <div className="row container">
                  <div className="column">
                    <div className="row">
                      {/* <h4
                        style={{
                          fontSize: 14,
                          paddingBottom: 15,
                          paddingRight: 100,
                          paddingLeft: 50,
                        }}
                      >
                        Profile Picture:{" "}
                      </h4>
                      <br></br> */}
                      <div>
                        <React.Fragment>
                          <img
                            src={profilePicture !== null && profilePicture !== '' ? profilePicture : defaultimage}
                            alt="profilepicture"
                            height={200}
                            width={200}
                          />
                        </React.Fragment>
                      </div>

                    </div>
                    <br></br>

                    {/* <div className='col-md-3'>
                                        <ul className="list-group">
                                            <li onClick={() => this.props.history.push('/applypage/jobposition')} style={{ cursor: 'pointer' }} className="list-group-item">
                                                Job Position
                                    </li>
                                            <li onClick={() => this.props.history.push('/applypage/jobapplied')} style={{ cursor: 'pointer' }} className="list-group-item">
                                                Applied List
                                    </li>

                                        </ul>
                                    </div> 
                  <div className="col-md-9"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeMaster,
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  user: state.user.user,
  university: state.user.university,
  master: state.user.master,
  jobposition: state.user.jobposition,
  subscription: state.user.subscription,
  profilePicture: state.user.profilePicture,
});
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeBack);
