// IMPORT PACKAGE REFERENCES
import React from 'react';
import Header from "../components/Header/HeaderLogin"
// COMPONENT

const UnderConstruction = props => (
    <React.Fragment>
        <Header {...props} />
        <div className="underconstruction">
            <div className="container">
                <div className="content">
                    <h1 className="logo text-center">SARVAM</h1>
                    <p>&nbsp;</p>
                    <h1 align="center">We&apos;re Coming Soon</h1>
                    <p align="center">We are working very hard on our website. Stay tuned!.</p>
                </div>
            </div>
        </div>
    </React.Fragment>
);

export default UnderConstruction;