import React from 'react';
import { CustomHeaders } from './CustomHeaders';
import {storeJobPosition } from '../../../_reducers/reducer.user';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { toast, ToastContainer } from 'react-toastify';
import API from '../../../_api'
class JobPosition extends React.Component {
    componentDidMount() {
        //let { user: { student: { program } } } = this.props;

        API.Candidate.GetAppliedList(this.props)
            .then((res) => {
                if (res.status !== '1') {
                    if (res.length > 0) {
                        this.setState({
                            appliedList: res
                        /*}, () => {
                            let appliedList = this.state.appliedList;*/
                        });
                    }
                    else {
                        toast("There is no applied programs.", { autoClose: true });
                    }
                }
            })

    }
    state = {
        appliedList: []
    }
    render() {
        let { history: { location } } = this.props;
        let { appliedList } = this.state;
        return (
            <section id="content">
                <CustomHeaders {...location} />
                <ToastContainer style={{ marginTop: 50 }} />
                <div className='content-tab' style={{ padding: 10 }}>
                    <h4>Applied Programs</h4>
                    <div className='content-height' style={{ height: '78vh' }}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Job Title</th>
                                    <th>Job Function</th>
                                    <th>Industry</th>
                                    {/* <th>Applied on</th> */}
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appliedList.map(item => (
                                    <tr>
                                        <td>{item.jobTitle}</td>
                                        <td>{item.jobFunction}</td>
                                        <td>{item.industry}</td>
                                        {/* <td>{moment(item.createdAt).format('DD MMMM YYYY')}</td> */}
                                        <td>{item.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    storeJobPosition
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    subscription: state.user.subscription
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobPosition);