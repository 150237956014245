// IMPORT PACKAGE REFERENCES

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ButtonLogin from './ButtonLogin';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// COMPONENT

class Header extends Component {

    state = { title: 'ID', status: 'id' };
    onchange = (title, status) => {
        this.setState({
            title: title,
            status: status
        })
    }


    render() {
        let subscription = this.props.subscription;
        return (
            <header className="nav-bar">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        {/*eslint-disable no-script-url*/}
                        <NavLink to={`/home/${subscription.subscription}`} className="logo-text navbar-brand"><span><img style={{ height: 35 }} alt="Sarvam" src={'/images/sarvamnamewhite.png'} /></span></NavLink>
                        <div className="pull-right">
                            <img alt='' src={subscription ? subscription.logo ? subscription.logo : '' : ''} style={{ maxWidth: "80px" }} />
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <div className="col-md-4 offset-md-8">
                                <ul className="nav-menu navbar-nav">
                                    <li className="nav-item">
                                        <ButtonLogin title="Sign In" goto={(path, from) => {
                                            this.props.history.push({ pathname: path, state: { from } })
                                        }}
                                            newlogin='0' status="student" />
                                    </li>
                                    {/* <li className="nav-item">                                   
                                        <ButtonLogin title="Sign Up" goto={(e) => this.props.history.push(e)} newlogin='0' status="signup" />
                                    </li>                                     */}
                                </ul>
                            </div>
                        </div>

                    </div>
                </nav>
            </header>
        )
    }
};


const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    subscription: state.user.subscription
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);