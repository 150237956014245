import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedin } from '../../_reducers/reducer.user';
import Header from '../../components/Header/HeaderLogin';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        let logged = isLoggedin();
        if (logged) {
            return (
                <React.Fragment>
                    <Header {...props} />
                    <Component {...props} />
                </React.Fragment>
            )
        } else {
            return (                
                <React.Fragment>
                    <Header {...props} />
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                </React.Fragment>
            )
        }
    }
    } />
)
export default PrivateRoute;
