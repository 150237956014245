// IMPORT PACKAGE REFERENCES
/*eslint-disable no-unused-vars*/
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
//Language
import SelectValidator from "../../components/Validators/SelectValidator";
import TextAreaValidator from "../../components/Validators/TextAreaValidator";
import TextValidator from "../../components/Validators/TextValidator";
import CheckBoxValidator from "../../components/Validators/CheckBoxValidator";
import { ValidatorForm } from "react-form-validator-core";
import EducationDetail from "../Apply/EducationDetail";

//redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import API from "../../_api";
import { storeMaster, storeProfilePicture, } from "../../_reducers/reducer.user";
import { devLog } from "../../_helper/helper.methods";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Loader";
import moment from "moment";
import { push } from 'react-router-redux'

import $ from "jquery";
import FileUpload from "../Validators/FileUpload";
// COMPONENT

class ApplyContent extends Component {
  constructor(props) {
    super(props);
    let {
      password: { userUID },
    } = props.user;
    this.state = {
      step: 0,
      open: false,
      imgs: [],
      order: null,
      header_title: null,
      button_tilte: "Apply",
      Fees: null,
      candidate: this.candidateApplyJSON(),
      loading: false,
      candidateFields: this.getcandidateFields(),
      newEducation: {
        uid: null,
        rmsCandidateProfileUID: this.props.user.profileUID,
        qualification: null,
        field: null,
        grade: null,
        specialAchievement: null,
        periodFrom: null,
        periodTo: null,
        institution: null,
      },
      newEducationAttachment: {
        uid: null,
        rmsCandidateProfileUID: this.props.user.profileUID,
        certificateName: null,
        issuedBy: null,
        yearObtained: moment(Date()).format("YYYY"),
        fileURL: null,
      },
      newExperience: {
        uid: null,
        rmsCandidateProfileUID: this.props.user.profileUID,
        company: null,
        position: null,
        salary: null,
        jobDuties: null,
        fromMonth: null,
        fromYear: null,
        toMonth: null,
        toYear: null,
        present: false,
        salaryCurrency: null,
      },
      newReferee: {
        uid: null,
        rmsCandidateProfileUID: this.props.user.profileUID,
        refereeName: null,
        company: null,
        position: null,
        contactNo: null,
        contactEmail: null,
      },
    };
  }

  candidateApplyJSON = () => {
    let {
      password: { userUID },
    } = this.props.user;
    return {
      profile: {
        uid: this.props.user.profileUID,
        profileID: null,
        title: null,
        firstName: null,
        lastName: null,
        gender: null,
        dob: null,
        email: null,
        liveIn: null,
        primaryContactno: null,
        secondaryContactNo: null,
        marital: null,
        nationality: null,
        identifierNo: null,
        identifierType: null,
        educationLevel: null,
        workExp: null,
        latestPosition: null,
        latestCareerLevel: null,
        latestSalary: null,
        latestSalaryCur: null,
        expectedSalary: null,
        expectedSalaryCur: null,
        availability: null,
        availabilityType: null,
        careerObjective: null,
        skills: null,
        jobFunctions: null,
        industries: null,
        resumeFileURL: null,
        status: null,
        photoURL: null,
        location: null,
        address: null,
        postalCode: null,
        country: null,
      },

      experience: [],
      education: [],
      certificate: [],
      referee: [],
      upload: {
        uid: null,
        rmsCandidateProfileUID: this.props.user.profileUID,
        resumeURL: null,
        resumeName: null,
      },
    };
  };

  getcandidateFields = () => {
    return {
      candidateProfile: {
        requiredFields: [],
        title: false,
        firstname: false,
        lastname: false,
        Gender: false,
        dob: false,
        email: false,
        liveIn: false,
        primaryContactno: false,
        secondaryContactNo: false,
        marital: false,
        nationality: false,
        identifier: false,
        educationLevel: false,
        workExp: false,
        latestPosition: false,
        latestCareerLevel: false,
        latestSalary: false,
        expectedSalary: false,
        availability: false,
        careerObjective: false,
        skills: false,
        jobFunctions: false,
        industries: false,
        resumeFileUrl: false,
        status: false,
        photoUrl: false,
        location: false,
        address: false,
        postalcode: false,
        country: false,
        gender: false,
      },
      candidateEducation: {
        requiredFields: [],
        qualification: false,
        field: false,
        grade: false,
        specialAchievement: false,
        period: false,
        institution: false,
      },
      candidateCertificate: {
        requiredFields: [],
        certificateName: false,
        issuedBy: false,
        yearObtained: false,
        fileUrl: false,
      },
      candidateExperence: {
        requiredFields: [],
        company: false,
        position: false,
        salary: false,
        jobDuties: false,
        from: false,
        to: false,
        present: false,
        salaryCurrency: false,
      },
      candidateReferee: {
        requiredFields: [],
        refereeName: false,
        company: false,
        position: false,
        contactNo: false,
        contactEmail: false,
      },
      candidateUpload: {
        requiredFields: [],
        resumeURL: false,
      },
    };
  };
  onChangeTab = (obj, e) => {
    for (
      var index = 0;
      index < document.getElementsByClassName("tab-content").length;
      index++
    ) {
      document.getElementsByClassName("tab-content")[index].style.display =
        "none";
    }
    document.getElementById(obj).style.display = "flex";
    if (e) {
      e.preventDefault();
    }
  };

  componentDidMount() {
    this.loadModuleMaster();
    this.setExitingValues();
    let {
      user: { from },
      jobposition,
    } = this.props;
    if (jobposition.jobTitle) {
      this.setState(
        {
          candidateFields: JSON.parse(jobposition.candidateFields),
        },
        () => {
          if (from === "signin") {
            this.onChangeTab("personal-data");
          }
        }
      );
    }
  }
  loadModuleMaster = () => {
    API.Master.List(this.props.user, this.props.subscription.subscription).then(
      (res) => {
        if (res.status !== "1") {
          let mlist = {
            ...this.props.master,
            modulemaster: {
              ...res,
            },
          };
          devLog("mliust", mlist);
          this.props.storeMaster(mlist);
          this.setExitingValues();
        } else {
          // toast.error(res.message, { autoClose: true });
        }
      }
    );
  };
  setExitingValues = async () => {
    let {
      user: { password },
      jobposition,
    } = this.props;
    let srec = this.state.candidate;
    if (password.profile.length > 0) {
      srec.profile = password.profile[0];
      srec.profile.dob = password.profile[0].dob
        ? moment(password.profile[0].dob).format("YYYY-MM-DD")
        : "";
      if (srec.profile.photoURL) {
        let res = await API.Master.AccessBlob(password, srec.profile.photoURL);
        if (res.status !== "1") {
          this.setState({
            profilePicture: res.message,
          });
        } else {
          // toast.error(res.message);
        }
      }
    }
    if (password.education.length > 0) {
      srec.education = password.education;
    }
    if (password.experience.length > 0) {
      srec.experience = password.experience;
    }
    if (password.certificate.length > 0) {
      srec.certificate = password.certificate;
    }
    if (password.referee.length > 0) {
      srec.referee = password.referee;
    }
    if (password.upload.length > 0) {
      srec.upload = password.upload[0];
    }

    if (jobposition.jobTitle) {
      srec.jobPosition = { jobPosUID: jobposition.jobPosUID };
    }
    this.setState({
      candidate: srec,
    });
  };
  removeItem = (id, name) => {
    if (name === "experience") {
      let experience = this.state.candidate.experience;
      experience.splice(id, 1);
      this.setState({
        candidate: {
          ...this.state.candidate,
          experience,
        },
      });
    } else if (name === "education") {
      let education = this.state.candidate.education;
      education.splice(id, 1);
      this.setState({
        candidate: {
          ...this.state.candidate,
          education,
        },
      });
    } else if (name === "certificate") {
      let certificate = this.state.candidate.certificate;
      certificate.splice(id, 1);
      this.setState({
        candidate: {
          ...this.state.candidate,
          certificate,
        },
      });
    } else if (name === "referee") {
      let referee = this.state.candidate.referee;
      referee.splice(id, 1);
      this.setState({
        candidate: {
          ...this.state.candidate,
          referee,
        },
      });
    }
  };

  autoSave = () => {
    devLog(JSON.stringify(this.state.candidate));
    let candidate = this.state.candidate;
    let { password } = this.props.user;
    API.Candidate.AutoSave(
      this.props,
      JSON.stringify(this.state.candidate)
    ).then((res) => {
      if (res.status === "1") {
        // toast.error(res.message, { autoClose: true });
      }
      return true;
    });
  };
  onChange = async (event, tab) => {
    let candidate = this.state.candidate;
    devLog("Candidate--------", candidate);
    if (tab && tab.includes("otherinfo")) {
      if (event.target.type === "checkbox") {
        this.setState({
          candidate: {
            ...candidate,
            misc: {
              ...candidate.misc,
              [event.target.name]: event.target.checked,
            },
          },
        });
      } else if (event.target.type === "file") {
        let name = event.target.name;
        try {
          if (event.target.files.length > 0) {
            let response = await API.Master.UploadBlob(
              this.props.user.password,
              event.target.files[0]
            );
            if (response.status !== "1") {
              this.setState({
                candidate: {
                  ...candidate,
                  misc: {
                    ...candidate.misc,
                    [name]: response.message,
                  },
                },
              });
            } else {
              toast(response.message, { autoClose: true });
            }
          }
        } catch (error) {
          toast(error, { autoClose: true });
        }
      } else {
        this.setState({
          candidate: {
            ...candidate,
            misc: {
              ...candidate.misc,
              [event.target.name]: event.target.value,
            },
          },
        });
      }
    } else if (tab && tab.includes("experience")) {
      if (event.target.type === "checkbox") {
        this.setState({
          newExperience: {
            ...this.state.newExperience,
            toMonth: event.target.checked
              ? ""
              : this.state.newExperience.toMonth,
            toYear: event.target.checked ? "" : this.state.newExperience.toYear,
            [event.target.name]: event.target.checked,
          },
        });
      } else {
        this.setState({
          newExperience: {
            ...this.state.newExperience,
            [event.target.name]: event.target.value,
          },
        });
      }
    } else if (tab && tab.includes("certificate")) {
      this.setState({
        newEducationAttachment: {
          ...this.state.newEducationAttachment,
          [event.target.name]: event.target.value,
        },
      });
    } else if (tab && tab.includes("experience")) {
      let candidate = this.state.candidate;
      this.setState({
        experience: {
          ...this.state.experience,
          [event.target.name]: event.target.value,
        },
      });
    } else if (tab && tab.includes("referee")) {
      this.setState({
        newReferee: {
          ...this.state.newReferee,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      if (event.target.type === "checkbox") {
        this.setState({
          candidate: {
            ...candidate,
            profile: {
              ...candidate.profile,
              [event.target.name]: !candidate.profile[event.target.value],
            },
          },
        });
      } else {
        this.setState({
          candidate: {
            ...candidate,
            profile: {
              ...candidate.profile,
              [event.target.name]:
                event.target.name === "contactPref"
                  ? event.target.value.toString()
                  : event.target.value,
            },
          },
        });
      }
    }
  };

  onProfilePictureUpload = async (e) => {
    let files = e.target.files;
    let { candidate } = this.state;
    if (files.length > 0) {
      try {
        this.setState({ loading: true });
        let response = await API.Master.UploadBlob(this.props, files[0]);
        this.setState({ loading: false });

        if (response.status !== "1") {
          let reader = new FileReader();
          reader.onloadend = () => {
            this.props.storeProfilePicture(response.message);                 // <============= Storing Profile picture locally to access any where.
            this.setState(
              {
                profilePicture: reader.result,
                candidate: {
                  ...candidate,
                  profile: {
                    ...candidate.profile,
                    photoURL: response.message,
                  },
                },
              },
              () => {
                this.autoSave();
              }
            );
          };
          reader.readAsDataURL(files[0]);
        } else {
          toast(response.message);
        }
      } catch (error) {
        this.setState({ loading: false });
        toast(error);
        console.error(error);
        return;
      }
    }
  };
  onChangeCertificate = (event) => {
    let { name, value, files, type } = event.target;
    let newEducationAttachment = this.state.newEducationAttachment;
    this.setState({
      newEducationAttachment: {
        ...newEducationAttachment,
        [name]: type === "file" ? files[0] : value,
      },
    });
  };
  onResumeDownload = () => {
    let upload = this.state.candidate.upload;
    if (upload.resumeURL) {
      let {
        user: { password },
      } = this.props;
      if (upload.resumeURL !== null && upload.resumeURL !== "") {
        API.Master.AccessBlob(password, upload.resumeURL).then((res) => {
          if (res.status !== "1") {
            window.open(
              res.message,
              "sharer",
              "toolbar=0,status=0,width=548,height=325"
            );
          } else {
            // toast.error(res.message);
          }
        });
      }
    } else {
      alert("Resume not found!");
    }
  };
  onChangeResume = async (event) => {
    let { name, value, files, type } = event.target;
    if (files.length > 0) {
      let file = files[0];
      if (!file.type.includes("pdf") && !file.type.includes("msword")) {
        toast.error("Resume can be pdf or doc");
        return;
      }
    }
    let upload = this.state.candidate.upload;
    try {
      let response = await API.Master.UploadBlob(this.props, files[0]);
      if (response.status !== "1") {
        upload.resumeURL = response.message;
      } else {
        this.setState({ loading: false });
        toast(response.message, { autoClose: true });
        return;
      }
    } catch (error) {
      this.setState({ loading: false });

      toast(error, { autoClose: true });

      console.error(error);
      return;
    }
    upload.resumeName = files[0].name;
    //upload.resumeURL = files[0];
    this.setState(
      {
        ...this.props.candidate,
        upload: {
          upload,
        },
      },
      () => {
        this.autoSave();
      }
    );
  };
  AddEducationAttachment = async (event) => {
    this.setState({ loading: true });

    let newEducationAttachment = this.state.newEducationAttachment;
    if (newEducationAttachment.fileURL) {
      try {
        let response = await API.Master.UploadBlob(
          this.props,
          newEducationAttachment.fileURL
        );
        if (response.status !== "1") {
          newEducationAttachment.fileURL = response.message;
        } else {
          this.setState({ loading: false });
          toast(response.message, { autoClose: true });
          return;
        }
      } catch (error) {
        this.setState({ loading: false });

        toast(error, { autoClose: true });

        console.error(error);
        return;
      }
    }
    let certificateArray = this.state.candidate.certificate;
    certificateArray.push(this.state.newEducationAttachment);
    this.setState(
      {
        loading: true,
        candidate: {
          ...this.state.candidate,
          certificate: certificateArray,
        },
      },
      async () => {
        await this.autoSave();
        this.setState({
          newEducationAttachment: {
            uid: null,
            rmsCandidateProfileUID: this.props.user.profileUID,
            certificateName: null,
            issuedBy: null,
            yearObtained: moment(Date()).format("YYYY"),
            fileURL: null,
          },
          loading: false,
        });
      }
    );
  };
  onChangeEducation = (event, index) => {
    let { name, value, files, type } = event.target;
    let newEducation = this.state.newEducation;
    this.setState({
      newEducation: {
        ...newEducation,
        [name]: value,
      },
    });
  };
  addEducation = async (event) => {
    this.setState({ loading: true });
    let newEducation = this.state.newEducation;
    let education = this.state.candidate.education;
    education.push(newEducation);

    this.setState(
      {
        candidate: {
          ...this.state.candidate,
          education,
        },
        newEducation: {
          ...this.state.newEducation,
        },
      },
      async () => {
        let {
          password: { userUID },
        } = this.props.user;
        await this.autoSave();
        this.setState({
          loading: false,
          newEducation: {
            uid: null,
            rmsCandidateProfileUID: this.props.user.profileUID,
            qualification: null,
            field: null,
            grade: null,
            specialAchievement: null,
            periodFrom: null,
            periodTo: null,
            institution: null,
          },
        });
      }
    );
  };

  addExperience = () => {
    let experienceArray = this.state.candidate.experience;
    experienceArray.push(this.state.newExperience);
    this.setState(
      {
        loading: true,
        candidate: {
          ...this.state.candidate,
          experience: experienceArray,
        },
      },
      async () => {
        await this.autoSave();
        this.setState({
          newExperience: {
            uid: null,
            rmsCandidateProfileUID: this.props.user.profileUID,
            company: null,
            position: null,
            salary: null,
            jobDuties: null,
            fromMonth: null,
            fromYear: null,
            toMonth: null,
            toYear: null,
            present: false,
            salaryCurrency: null,
          },
          loading: false,
        });
      }
    );
  };
  addReferee = () => {
    let refereeArray = this.state.candidate.referee;
    refereeArray.push(this.state.newReferee);
    this.setState(
      {
        loading: true,
        candidate: {
          ...this.state.candidate,
          referee: refereeArray,
        },
      },
      async () => {
        await this.autoSave();
        this.setState({
          newReferee: {
            uid: null,
            rmsCandidateProfileUID: this.props.user.profileUID,
            refereeName: null,
            company: null,
            position: null,
            contactNo: null,
            contactEmail: null,
          },
          loading: false,
        });
      }
    );
  };
  onApply = () => {
    this.setState({ loading: true });
    let { candidate } = this.state;
    let { password } = this.props.user;

    if (!candidate.upload.resumeURL) {
      this.setState({ loading: false });
      toast.warn("You need to upload resume before apply");
    }

    if (
      candidate.profile.title !== null &&
      candidate.profile.title !== "" &&
      candidate.profile.firstName !== null &&
      candidate.profile.firstName !== "" &&
      candidate.profile.lastName !== null &&
      candidate.profile.lastName !== ""
    ) {
      API.Candidate.Apply(password, JSON.stringify(candidate)).then((res) => {
        // this.setState({ loading: false });
        if (res.status !== "1") {
          toast("Applied Successfully", { autoClose: true });
          setTimeout(() => {
            this.props.goto("/applypage/welcome");
          }, 1000);
        } else {
          // toast(res.message, { autoClose: true });
        }
      });
    } else {
      this.setState({ loading: false });
      toast.warn("Check and please fill the candidate details");
    }
  };

  render() {
    let defaultimage = "/images/profile-default.jpg";
    let {
      user: { password },
      jobposition,
      master,
      master: { modulemaster },
    } = this.props;
    const {
      candidateFields: {
        candidateCertificate,
        candidateEducation,
        candidateReferee,
        candidateUpload,
        candidateExperence,
        candidateProfile,
      },
      profilePicture,
      newExperience,
      newEducation,
      newEducationAttachment,
      newReferee,
      candidate: {
        profile,
        experience,
        education,
        upload,
        certificate,
        referee,
      },
    } = this.state;
    return (
      <section id="content">
        <header className="tabs-bar">
          <ul className="tabs-lists">
            <li>
              <NavLink to="/applypage/welcome" className="tab">
                Welcome
              </NavLink>
            </li>

            {/* <li><a href='#' onClick={(e) => this.onChangeTab('university', e)} className="tab">university&nbsp;&amp;&nbsp;program</a></li> */}
            <li>
              <a
                href=""
                onClick={(e) => this.onChangeTab("personal-data", e)}
                className="tab"
              >
                Profile&nbsp;data
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={(e) => this.onChangeTab("education-data", e)}
                className="tab"
              >
                education&nbsp;data
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={(e) => this.onChangeTab("certificate-data", e)}
                className="tab"
              >
                certificate&nbsp;data
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={(e) => this.onChangeTab("parents-data", e)}
                className="tab"
              >
                Experience&nbsp;data
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={(e) => this.onChangeTab("other-info", e)}
                className="tab"
              >
                Referee&nbsp;data
              </a>
            </li>
            {/* <li>
              <a
                href=""
                onClick={(e) => this.onChangeTab("declaration", e)}
                className="tab"
              >
                Resume
              </a>
            </li> */}
            <li>
              <a
                href=""
                onClick={(e) => this.onChangeTab("apply", e)}
                className="tab"
              >
                apply
              </a>
            </li>
          </ul>
        </header>
        <h5 style={{ margin: 0, marginTop: 10 }}>
          Job Selected : {jobposition.jobTitle}
        </h5>
        <Loader loading={this.state.loading} text="Please Wait" />
        <ToastContainer style={{ top: "10em" }} />
        <div id="introduction" className="tab-content row">
          <div className="col-md-12">
            <div className="content-left">
              <div className="row" style={{ height: "68vh" }}>
                <div className="col-md-6">
                  <div className="content-left">
                    <h2>
                      Dear {password ? password.profile[0].firstName : ""},
                    </h2>
                    <p>
                      Thank you for starting your application to SARVAM. We are
                      looking forward to learning more about you.
                    </p>
                    <p>
                      I hope that you find the application process
                      straightforward. you are free to complete each section of
                      the application in the order.
                    </p>
                    <p>
                      If you have any questions or need any guidance, our
                      support staff will be happy to help.
                    </p>
                    <p>Best of Luck.</p>
                    <div className="support-team">
                      <h3>
                        Management Team <br />
                        <span>SARVAM</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="content-right">
                    <h2>Application Steps</h2>
                    <div className="step-items">
                      <ul>
                        <li>
                          <span>1. Profile data</span>
                        </li>
                        <li>
                          <span>2. Education data</span>
                        </li>
                        <li>
                          <span>3. Certificate data</span>
                        </li>
                        <li>
                          <span>4. Experience data</span>
                        </li>
                        <li>
                          <span>5. Referee data</span>
                        </li>
                        <li>
                          <span>6. Apply</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ background: "#f8f9fa", height: 1 }} />
              <button
                id="btt_next"
                className="btn-next"
                onClick={() => this.onChangeTab("personal-data")}
              >
                <span>Next</span>
              </button>
            </div>
          </div>
          {/* <div style={{height:"20%"}}> */}
        </div>

        <div
          id="personal-data"
          className="tab-content row"
          style={{ display: "none" }}
        >
          <div className="col-md-12">
            <ValidatorForm
              ref={(ref) => {
                this.profileform = ref;
              }}
              onSubmit={() => this.onChangeTab("education-data")}
            >
              <div className="content-left">
                <h2>Profile Data</h2>
                <div className="content-height">
                  <div>
                    <div className="row" style={{ marginBottom: "1rem" }}>
                      {candidateProfile.title && (
                        <SelectValidator
                          id="title"
                          name="title"
                          className="col-md-4"
                          onClose={this.autoSave}
                          onChange={this.onChange}
                          data={master.salutationList.map(({ text }) => text)}
                          options={{ placeholder: "Choose Title" }}
                          value={profile.title}
                          required={
                            candidateProfile.requiredFields.indexOf("title") !==
                            -1
                          }
                        />
                      )}
                      {candidateProfile.firstname && (
                        <TextValidator
                          id="firstName"
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.firstName}
                          className="col-md-4"
                          name="firstName"
                          required={
                            candidateProfile.requiredFields.indexOf(
                              "firstname"
                            ) !== -1
                          }
                          placeholder="First Name"
                        />
                      )}
                      {candidateProfile.lastname && (
                        <TextValidator
                          id="lastName"
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.lastName}
                          className="col-md-4"
                          name="lastName"
                          placeholder="Last Name"
                          required={
                            candidateProfile.requiredFields.indexOf(
                              "lastname"
                            ) !== -1
                          }
                        />
                      )}
                      {candidateProfile.gender && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.gender}
                          name="gender"
                          required={
                            candidateProfile.requiredFields.indexOf(
                              "gender"
                            ) !== -1
                          }
                          className="col-md-4"
                          data={master.genderList.map(({ text }) => text)}
                          options={{ placeholder: "Choose Gender" }}
                        />
                      )}
                      {candidateProfile.dob && (
                        <TextValidator
                          onChange={this.onChange}
                          value={profile.dob}
                          className="col-md-4"
                          name="dob"
                          required={
                            candidateProfile.requiredFields.indexOf("dob") !==
                            -1
                          }
                          placeholder="Date of Birth"
                          type="text"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => {
                            if (!profile.dob) {
                              e.target.type = "text";
                            }
                            this.autoSave();
                          }}
                        />
                      )}
                      {candidateProfile.liveIn && (
                        <TextValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.liveIn}
                          className="col-md-4"
                          name="liveIn"
                          placeholder="Live In"
                          required={
                            candidateProfile.requiredFields.indexOf(
                              "livein"
                            ) !== -1
                          }
                        />
                      )}

                      {candidateProfile.email && (
                        <TextValidator
                          onBlur={this.autoSave}
                          onChange={this.onChange}
                          value={profile.email}
                          className="col-md-4"
                          name="email"
                          type="email"
                          placeholder="Email"
                          required={
                            candidateProfile.requiredFields.indexOf("email") !==
                            -1
                          }
                          disabled={candidateProfile.email}
                        />
                      )}
                      {candidateProfile.primaryContactno && (
                        <TextValidator
                          id="primaryContactno"
                          onBlur={this.autoSave}
                          onChange={this.onChange}
                          value={profile.primaryContactno}
                          className="col-md-4"
                          type="number"
                          required={
                            candidateProfile.requiredFields.indexOf(
                              "primaryContactno"
                            ) !== -1
                          }
                          name="primaryContactno"
                          placeholder="Primary Contact No"
                        />
                      )}
                      {candidateProfile.secondaryContactNo && (
                        <TextValidator
                          onBlur={this.autoSave}
                          onChange={this.onChange}
                          value={profile.secondaryContactNo}
                          className="col-md-4"
                          name="secondaryContactNo"
                          type="number"
                          placeholder="Secondary Contact No"
                        />
                      )}
                      {candidateProfile.address && (
                        <TextAreaValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.address}
                          className="col-md-4"
                          name="address"
                          placeholder="Address"
                          required={
                            candidateProfile.requiredFields.indexOf(
                              "address"
                            ) !== -1
                          }
                        />
                      )}
                      {candidateProfile.marital && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          name="marital"
                          className="col-md-4"
                          data={master.maritalList.map(({ text }) => text)}
                          options={{ placeholder: "Choose Marital Status" }}
                          value={profile.marital}
                        />
                      )}

                      {candidateProfile.nationality && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.nationality}
                          name="nationality"
                          data={master.countryList.map(({ text }) => text)}
                          className="col-md-4"
                          options={{ placeholder: "Nationality" }}
                        />
                      )}
                      {candidateProfile.educationLevel && (
                        <SelectValidator
                          className="col-md-4"
                          onClose={this.autoSave}
                          onChange={this.onChange}
                          value={profile.educationLevel}
                          name="educationLevel"
                          data={master.educationDegreeList.map(
                            ({ text }) => text
                          )}
                          options={{ placeholder: "Education Level" }}
                        />
                      )}
                      {candidateProfile.identifier && (
                        <SelectValidator
                          className="col-md-4"
                          onClose={this.autoSave}
                          onChange={this.onChange}
                          value={profile.identifierType}
                          name="identifierType"
                          data={modulemaster.idCardTypeList.map(
                            ({ text }) => text
                          )}
                          options={{ placeholder: "ID Type" }}
                        />
                      )}
                      {candidateProfile.identifier && (
                        <TextValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.identifierNo}
                          className="col-md-4"
                          name="identifierNo"
                          placeholder="ID Number"
                        />
                      )}
                      {candidateProfile.workExp && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.workExp}
                          name="workExp"
                          data={modulemaster.yearsofWorkExpList.map(
                            ({ text }) => text
                          )}
                          className="col-md-4"
                          options={{ placeholder: "Work Experience" }}
                        />
                      )}
                      {candidateProfile.latestPosition && (
                        <TextValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.latestPosition}
                          className="col-md-4"
                          name="latestPosition"
                          placeholder="Latest Position"
                        />
                      )}
                      {candidateProfile.latestSalary && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.latestSalaryCur || ""}
                          name="latestSalaryCur"
                          className="col-md-4"
                          data={master.currencyList.map(({ text }) => text)}
                          options={{ placeholder: "Latest Salary Currency" }}
                        />
                      )}
                      {candidateProfile.latestSalary && (
                        <TextValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.latestSalary}
                          className="col-md-4"
                          type="number"
                          name="latestSalary"
                          placeholder="Latest Salary"
                        />
                      )}

                      {candidateProfile.latestCareerLevel && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.latestCareerLevel}
                          name="latestCareerLevel"
                          className="col-md-4"
                          data={master.rmsCandidateCareerLevelList.map(
                            ({ text }) => text
                          )}
                          options={{ placeholder: "Latest Career Level" }}
                        />
                      )}
                      {candidateProfile.expectedSalary && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.expectedSalaryCur}
                          name="expectedSalaryCur"
                          className="col-md-4"
                          data={master.currencyList.map(({ text }) => text)}
                          options={{ placeholder: "Expected Salary Currency" }}
                        />
                      )}
                      {candidateProfile.expectedSalary && (
                        <TextValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.expectedSalary}
                          className="col-md-4"
                          type="number"
                          name="expectedSalary"
                          placeholder="Expected Salary"
                        />
                      )}
                      {candidateProfile.availability && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.availabilityType}
                          name="availabilityType"
                          className="col-md-4"
                          data={master.rmsCandidateAvailTypeList.map(
                            ({ text }) => text
                          )}
                          options={{ placeholder: "Availability Type" }}
                        />
                      )}
                      {candidateProfile.availability && (
                        <TextValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.availability}
                          className="col-md-4"
                          name="availability"
                          placeholder="Availability"
                        />
                      )}
                      {candidateProfile.careerObjective && (
                        <TextAreaValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.careerObjective}
                          className="col-md-4"
                          name="careerObjective"
                          placeholder="Career Objective"
                        />
                      )}
                      {candidateProfile.skills && (
                        <TextValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.skills}
                          className="col-md-4"
                          name="skills"
                          placeholder="Skills"
                        />
                      )}
                      {candidateProfile.jobFunctions && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.jobFunctions}
                          name="jobFunctions"
                          className="col-md-4"
                          data={modulemaster.jobFunctionList.map(
                            ({ text }) => text
                          )}
                          options={{ placeholder: "Job Functions" }}
                        />
                      )}
                      {candidateProfile.industries && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.industries}
                          name="industries"
                          className="col-md-4"
                          data={modulemaster.industryList.map(
                            ({ text }) => text
                          )}
                          options={{ placeholder: "Industries" }}
                        />
                      )}
                      {/* {candidateProfile.address2
                                                && <TextValidator
                                                    onChange={this.onChange}
                                                    onBlur={this.autoSave}
                                                    value={profile.address2}
                                                    className="col-md-4"
                                                    name="address2"
                                                    placeholder="Address2"
                                                    required={candidateProfile.requiredFields.indexOf('address2') !== -1}
                                                />} */}
                      {candidateProfile.location && (
                        <TextValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.location}
                          className="col-md-4"
                          name="location"
                          placeholder="Location"
                        />
                      )}

                      {candidateProfile.postalcode && (
                        <TextValidator
                          onChange={this.onChange}
                          onBlur={this.autoSave}
                          value={profile.postalCode}
                          className="col-md-4"
                          name="postalCode"
                          placeholder="Postal Code"
                        />
                      )}
                      {candidateProfile.country && (
                        <SelectValidator
                          onChange={this.onChange}
                          onClose={this.autoSave}
                          value={profile.country}
                          name="country"
                          data={master.countryList.map(({ text }) => text)}
                          className="col-md-4"
                          options={{ placeholder: "Country" }}
                        />
                      )}
                    </div>
                    <div
                      className="row"
                      style={{ marginLeft: 0, marginRight: 0 }}
                    >
                      <div>
                        <h6>Profile Picture</h6>
                        <div style={{ marginRight: 50 }}>
                          <React.Fragment>
                            <div className="row">
                              <FileUpload
                                id="profilePicture"
                                className="col-md-12"
                                type="file"
                                name="profilePicture"
                                accept="image/*"
                                onChange={this.onProfilePictureUpload}
                              />
                            </div>
                          </React.Fragment>
                        </div>
                        {/* <input
                          id="profilePicture"
                          accept="image/*"
                          title="Profile Picture"
                          type="file"
                          onChange={this.onProfilePictureUpload}
                        /> */}
                      </div>
                      <div>
                        <h6>Resume</h6>
                        <React.Fragment>
                          <div className="row">
                            <FileUpload
                              id="upload"
                              className="col-md-12"
                              type="file"
                              name="resumeURL"
                              accept=".pdf,.doc"
                              onChange={(e) => this.onChangeResume(e)}
                              required={
                                candidateUpload.requiredFields.indexOf(
                                  "resumeURL"
                                ) !== -1
                              }
                            />
                          </div>
                          {upload.resumeURL ? (
                            <div className="row">
                              <div className="col-md-12">
                                <button
                                  type="button"
                                  className="btn-link"
                                  onClick={(e) => this.onResumeDownload(e)}
                                >
                                  Download Resume
                                </button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      </div>
                    </div>
                    <br></br>
                    <div>
                      <React.Fragment>
                        <div className="row">
                          <div className="col-md-12">
                            {profilePicture ? (
                              <img
                                src={profilePicture}
                                alt="profilepicture"
                                height={200}
                                width={200}
                              />
                            ) : (
                              <img
                                src={defaultimage}
                                alt="defaultimage"
                                height={200}
                                width={200}
                              />
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
                <hr style={{ background: "#f8f9fa", height: 1 }} />
                <button
                  id="btt_next"
                  type="button"
                  className="btn-next"
                  onClick={() => {
                    var inp = document.getElementById("upload");

                    if (upload.resumeURL === null && inp.files.length === 0) {
                      alert("Attachment Required");
                      document.getElementById("upload").focus();
                      return false;
                    } else {
                      this.onChangeTab("apply");
                    }
                  }}
                >
                  <span>Apply</span>
                </button>
                <button
                  id="btt_next"
                  type="submit"
                  className="btn-next"
                  onClick={() => {
                    var inp = document.getElementById("upload");

                    if (upload.resumeURL === null && inp.files.length === 0) {
                      alert("Attachment Required");
                      document.getElementById("upload").focus();

                      return false;
                    } else {
                      this.onChangeTab("education-data");
                    }
                  }}
                >
                  <span>Next</span>
                </button>

                <button
                  id="btt_next"
                  type="button"
                  className="btn-next"
                  onClick={() => this.onChangeTab("introduction")}
                >
                  <span>Prev</span>
                </button>
              </div>
            </ValidatorForm>
          </div>
        </div>
        <div
          id="education-data"
          className="tab-content row"
          style={{ display: "none" }}
        >
          <div className="col-md-12">
            <div className="content-left">
              <ValidatorForm onSubmit={this.addEducation}>
                <h2>
                  Candidate Education Background{" "}
                  <button
                    type="submit"
                    style={{ border: "none", fontSize: 15 }}
                    className="btn-link"
                  >
                    Add Education
                  </button>
                </h2>
                <div className="content-height">
                  <div>
                    <div className="row">
                      {candidateEducation.qualification && (
                        <SelectValidator
                          name="qualification"
                          className="col-md-4"
                          onSelect={this.onChangeEducation}
                          data={master.educationDegreeList.map(
                            ({ text }) => text
                          )}
                          options={{ placeholder: "Choose Program" }}
                          value={newEducation.qualification || ""}
                          required={
                            candidateEducation.requiredFields.indexOf(
                              "qualification"
                            ) !== -1
                          }
                        />
                      )}

                      {candidateEducation.institution && (
                        <TextValidator
                          onChange={this.onChangeEducation}
                          value={newEducation.institution || ""}
                          className="col-md-4"
                          name="institution"
                          placeholder="Institution"
                          required={
                            candidateEducation.requiredFields.indexOf(
                              "institution"
                            ) !== -1
                          }
                        />
                      )}
                      {candidateEducation.field && (
                        <TextValidator
                          onChange={this.onChangeEducation}
                          value={newEducation.field || ""}
                          className="col-md-4"
                          name="field"
                          placeholder="Field"
                          required={
                            candidateEducation.requiredFields.indexOf(
                              "field"
                            ) !== -1
                          }
                        />
                      )}
                      {candidateEducation.grade && (
                        <TextValidator
                          onChange={this.onChangeEducation}
                          value={newEducation.grade || ""}
                          className="col-md-4"
                          name="grade"
                          placeholder="Grade"
                          required={
                            candidateEducation.requiredFields.indexOf(
                              "grade"
                            ) !== -1
                          }
                        />
                      )}
                      {candidateEducation.specialAchievement && (
                        <TextValidator
                          onChange={this.onChangeEducation}
                          value={newEducation.specialAchievement || ""}
                          className="col-md-4"
                          name="specialAchievement"
                          placeholder="Special Achievement"
                          required={
                            candidateEducation.requiredFields.indexOf(
                              "specialacheivement"
                            ) !== -1
                          }
                        />
                      )}
                      {candidateEducation.period && (
                        <TextValidator
                          onChange={this.onChangeEducation}
                          value={newEducation.periodFrom || ""}
                          className="col-md-4"
                          name="periodFrom"
                          placeholder="Period From"
                          type="text"
                          required={
                            candidateEducation.requiredFields.indexOf(
                              "periodfrom"
                            ) !== -1
                          }
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => {
                            if (!newEducation.periodFrom) {
                              e.target.type = "text";
                            }
                          }}
                        />
                      )}
                      {candidateEducation.period && (
                        <TextValidator
                          onChange={this.onChangeEducation}
                          value={newEducation.periodTo || ""}
                          className="col-md-4"
                          name="periodTo"
                          placeholder="Period To"
                          type="text"
                          required={
                            candidateEducation.requiredFields.indexOf(
                              "periodto"
                            ) !== -1
                          }
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => {
                            if (!newEducation.periodTo) {
                              e.target.type = "text";
                            }
                          }}
                        />
                      )}
                    </div>

                    <div style={{ overflowX: "auto", height: 200 }}>
                      <table className="table">
                        <thead>
                          <tr>
                            {candidateEducation.qualification && (
                              <th>Qualification</th>
                            )}
                            {candidateEducation.institution && (
                              <th>Institution</th>
                            )}
                            {candidateEducation.field && <th>Field</th>}
                            {candidateEducation.grade && <th>Grade</th>}
                            {candidateEducation.specialAchievement && (
                              <th>Special Achievement</th>
                            )}
                            {candidateEducation.period && <th>From</th>}
                            {candidateEducation.period && <th>To</th>}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {education.map((item, id) => (
                            <tr>
                              {candidateEducation.qualification && (
                                <td>{item.qualification}</td>
                              )}
                              {candidateEducation.institution && (
                                <td>{item.institution}</td>
                              )}
                              {candidateEducation.field && (
                                <td>{item.field}</td>
                              )}
                              {candidateEducation.grade && (
                                <td>{item.grade}</td>
                              )}
                              {candidateEducation.specialAchievement && (
                                <td>{item.specialAchievement}</td>
                              )}
                              {candidateEducation.period && (
                                <td>{item.periodFrom}</td>
                              )}
                              {candidateEducation.period && (
                                <td>{item.periodTo}</td>
                              )}
                              <td>
                                {" "}
                                <button
                                  onClick={() =>
                                    this.removeItem(id, "education")
                                  }
                                  className="btn-link"
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ValidatorForm>
              <hr style={{ background: "#f8f9fa", height: 1 }} />
              <button
                id="btt_next"
                className="btn-next"
                onClick={() => this.onChangeTab("certificate-data")}
              >
                <span>Next</span>
              </button>
              <button
                id="btt_next"
                className="btn-next"
                onClick={() => this.onChangeTab("personal-data")}
              >
                <span>Prev</span>
              </button>
            </div>
          </div>
        </div>
        <div
          id="certificate-data"
          className="tab-content row"
          style={{ display: "none" }}
        >
          <div className="col-md-12">
            <div className="content-left">
              <ValidatorForm onSubmit={this.AddEducationAttachment}>
                <h2>
                  Certification Data{" "}
                  <button
                    style={{ border: "none", fontSize: 15 }}
                    type="submit"
                    className="btn-link"
                  >
                    Add Certificate
                  </button>
                </h2>
                <div className="content-height">
                  <div>
                    <div className="row">
                      {candidateCertificate.certificateName && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "certificate")}
                          onBlur={this.autoSave}
                          value={newEducationAttachment.certificateName || ""}
                          className="col-md-4"
                          name="certificateName"
                          required={
                            candidateCertificate.requiredFields.indexOf(
                              "certificateName"
                            ) !== -1
                          }
                          placeholder="Certificate Name"
                        />
                      )}
                      {candidateCertificate.issuedBy && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "certificate")}
                          onBlur={this.autoSave}
                          value={newEducationAttachment.issuedBy || ""}
                          className="col-md-2"
                          name="issuedBy"
                          required={
                            candidateCertificate.requiredFields.indexOf(
                              "issuedby"
                            ) !== -1
                          }
                          placeholder="Issued By"
                        />
                      )}
                      {candidateCertificate.yearObtained && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "certificate")}
                          onBlur={this.autoSave}
                          value={
                            newEducationAttachment.yearObtained ||
                            moment(Date()).format("YYYY")
                          }
                          className="col-md-2"
                          name="yearObtained"
                          min={1920}
                          max={moment().format("YYYY")}
                          required={
                            candidateCertificate.requiredFields.indexOf(
                              "yearObtained"
                            ) !== -1
                          }
                          type="number"
                          placeholder="Year Obtained"
                        />
                      )}
                      <div className="col-md-4">
                        {candidateCertificate.fileUrl && (
                          <div className="row" style={{ marginRight: 5 }}>
                            <div className="col-md-12">
                              <FileUpload
                                type="file"
                                name="fileURL"
                                onChange={(e) => this.onChangeCertificate(e)}
                                required={
                                  candidateCertificate.requiredFields.indexOf(
                                    "fileurl"
                                  ) !== -1
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* ))} */}

                    <div style={{ overflowX: "auto", height: 200 }}>
                      <table className="table">
                        <thead>
                          <tr>
                            {candidateCertificate.certificateName && (
                              <th>Certificate Name</th>
                            )}
                            {candidateCertificate.issuedBy && (
                              <th>Issued By</th>
                            )}
                            {candidateCertificate.yearObtained && (
                              <th>Year Obtained</th>
                            )}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {certificate.map((item, id) => (
                            <tr>
                              {candidateCertificate.certificateName && (
                                <td>{item.certificateName}</td>
                              )}
                              {candidateCertificate.issuedBy && (
                                <td>{item.issuedBy}</td>
                              )}
                              {candidateCertificate.yearObtained && (
                                <td>{item.yearObtained}</td>
                              )}
                              <td>
                                {" "}
                                <button
                                  onClick={() =>
                                    this.removeItem(id, "certificate")
                                  }
                                  className="btn-link"
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ValidatorForm>
              <hr style={{ background: "#f8f9fa", height: 1 }} />
              <button
                id="btt_next"
                className="btn-next"
                onClick={() => this.onChangeTab("parents-data")}
              >
                <span>Next</span>
              </button>
              <button
                id="btt_next"
                className="btn-next"
                onClick={() => this.onChangeTab("education-data")}
              >
                <span>Prev</span>
              </button>
            </div>
          </div>
        </div>
        <div
          id="parents-data"
          className="tab-content"
          style={{ display: "none" }}
        >
          <div className="col-md-12">
            <div className="content-left">
              <ValidatorForm onSubmit={this.addExperience}>
                <h2>
                  Experience Data{" "}
                  <button type="submit" className="btn-link">
                    Add Experience
                  </button>
                </h2>
                <div className="content-height">
                  <div>
                    <div className="row">
                      {candidateExperence.company && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "experience")}
                          value={newExperience.company || ""}
                          className="col-md-4"
                          name="company"
                          required={
                            candidateExperence.requiredFields.indexOf(
                              "company"
                            ) !== -1
                          }
                          placeholder="Company"
                        />
                      )}
                      {candidateExperence.position && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "experience")}
                          value={newExperience.position || ""}
                          className="col-md-4"
                          name="position"
                          required={
                            candidateExperence.requiredFields.indexOf(
                              "position"
                            ) !== -1
                          }
                          placeholder="Position"
                        />
                      )}
                      {candidateExperence.jobDuties && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "experience")}
                          value={newExperience.jobDuties || ""}
                          className="col-md-4"
                          name="jobDuties"
                          required={
                            candidateExperence.requiredFields.indexOf(
                              "jobduties"
                            ) !== -1
                          }
                          placeholder="Job Duties"
                        />
                      )}

                      {/* {candidateExperence.salaryCurrency
                                                && } */}
                      {candidateExperence.salary && (
                        <React.Fragment>
                          <SelectValidator
                            onSelect={(e) => this.onChange(e, "experience")}
                            onClose={this.autoSave}
                            value={newExperience.salaryCurrency}
                            name="salaryCurrency"
                            required={
                              candidateExperence.requiredFields.indexOf(
                                "salarycurrency"
                              ) !== -1
                            }
                            className="col-md-4"
                            data={master.currencyList.map(({ text }) => text)}
                            options={{ placeholder: "Currency" }}
                          />
                          <TextValidator
                            onChange={(e) => this.onChange(e, "experience")}
                            value={newExperience.salary || ""}
                            className="col-md-4"
                            name="salary"
                            type="number"
                            placeholder="Salary"
                            required={
                              candidateExperence.requiredFields.indexOf(
                                "salary"
                              ) !== -1
                            }
                          />
                        </React.Fragment>
                      )}
                      {candidateExperence.period && (
                        <React.Fragment>
                          <TextValidator
                            onChange={(e) => this.onChange(e, "experience")}
                            value={newExperience.fromMonth || ""}
                            className="col-md-2"
                            name="fromMonth"
                            type="number"
                            placeholder="From Month"
                            required={
                              candidateExperence.requiredFields.indexOf(
                                "period"
                              ) !== -1
                            }
                          />
                          <TextValidator
                            onChange={(e) => this.onChange(e, "experience")}
                            value={newExperience.fromYear || ""}
                            className="col-md-2"
                            name="fromYear"
                            max={moment().format("YYYY")}
                            type="number"
                            required={
                              candidateExperence.requiredFields.indexOf(
                                "period"
                              ) !== -1
                            }
                            placeholder="From Year"
                          />

                          <div className="row" style={{ marginLeft: 10 }}>
                            <div className="checkbox col-md-3">
                              <label>
                                <input
                                  type="checkbox"
                                  name="present"
                                  className="universitycheckbox"
                                  checked={newExperience.present}
                                  value=""
                                  onChange={(e) =>
                                    this.onChange(e, "experience")
                                  }
                                />
                                <span style={{ marginLeft: 10 }}>Present</span>
                              </label>
                            </div>
                            <TextValidator
                              onChange={(e) => this.onChange(e, "experience")}
                              value={newExperience.toMonth || ""}
                              className="col-md-3"
                              name="toMonth"
                              type="number"
                              disabled={newExperience.present}
                              required={
                                candidateExperence.requiredFields.indexOf(
                                  "tomonth"
                                ) !== -1
                              }
                              placeholder="To Month"
                            />
                            <TextValidator
                              onChange={(e) => this.onChange(e, "experience")}
                              value={newExperience.toYear || ""}
                              className="col-md-3"
                              name="toYear"
                              min={newExperience.fromYear}
                              max={moment().format("YYYY")}
                              type="number"
                              disabled={newExperience.present}
                              required={
                                candidateExperence.requiredFields.indexOf(
                                  "toyear"
                                ) !== -1
                              }
                              placeholder="To Year"
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    <div style={{ overflowX: "auto" }}>
                      <table className="table">
                        <thead>
                          <tr>
                            {candidateExperence.company && <th>Company</th>}
                            {candidateExperence.position && <th>Position</th>}
                            {candidateExperence.salaryCurrency && (
                              <th>Currency</th>
                            )}
                            {candidateExperence.salary && <th>Salary</th>}
                            {candidateExperence.jobDuties && (
                              <th>Job Duties</th>
                            )}
                            {candidateExperence.period && <th>From Month</th>}
                            {candidateExperence.period && <th>From Year</th>}
                            {candidateExperence.period && <th>To Month</th>}
                            {candidateExperence.period && <th>To Year</th>}
                            {candidateExperence.period && <th>Present</th>}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {experience.map((item, id) => (
                            <tr>
                              {candidateExperence.company && (
                                <td>{item.company}</td>
                              )}
                              {candidateExperence.position && (
                                <td>{item.position}</td>
                              )}
                              {candidateExperence.salaryCurrency && (
                                <td>{item.salaryCurrency}</td>
                              )}
                              {candidateExperence.salary && (
                                <td>{item.salary}</td>
                              )}
                              {candidateExperence.jobDuties && (
                                <td>{item.jobDuties}</td>
                              )}
                              {candidateExperence.period && (
                                <td>{item.fromMonth}</td>
                              )}
                              {candidateExperence.period && (
                                <td>{item.fromYear}</td>
                              )}
                              {candidateExperence.period && (
                                <td>{item.toMonth}</td>
                              )}
                              {candidateExperence.period && (
                                <td>{item.toYear}</td>
                              )}
                              {candidateExperence.period && (
                                <td>{item.present.toString()}</td>
                              )}
                              <td>
                                <button
                                  onClick={() =>
                                    this.removeItem(id, "experience")
                                  }
                                  className="btn-link"
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ValidatorForm>
              <hr style={{ background: "#f8f9fa", height: 1 }} />
              <div style={{ flex: 0.2 }}>
                <button
                  id="btt_next"
                  type="submit"
                  className="btn-next"
                  onClick={() => this.onChangeTab("other-info")}
                >
                  <span>Next</span>
                </button>
                <button
                  id="btt_next"
                  type="button"
                  className="btn-next"
                  onClick={() => this.onChangeTab("certificate-data")}
                >
                  <span>Prev</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          id="other-info"
          className="tab-content row"
          style={{ display: "none" }}
        >
          <div className="col-md-12">
            <div className="content-left">
              <ValidatorForm onSubmit={() => this.addReferee()}>
                <h2>
                  Referee Data{" "}
                  <button type="submit" className="btn-link">
                    Add Referee
                  </button>
                </h2>
                <div className="content-height">
                  <div>
                    <div className="row">
                      {candidateReferee.refereeName && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "referee")}
                          value={newReferee.refereeName || ""}
                          className="col-md-4"
                          name="refereeName"
                          required={
                            candidateReferee.requiredFields.indexOf(
                              "refereeName"
                            ) !== -1
                          }
                          placeholder="Referee Name"
                        />
                      )}
                      {candidateReferee.company && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "referee")}
                          value={newReferee.company || ""}
                          className="col-md-4"
                          name="company"
                          required={
                            candidateReferee.requiredFields.indexOf(
                              "company"
                            ) !== -1
                          }
                          placeholder="Company"
                        />
                      )}
                      {candidateReferee.position && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "referee")}
                          value={newReferee.position || ""}
                          className="col-md-4"
                          name="position"
                          required={
                            candidateReferee.requiredFields.indexOf(
                              "position"
                            ) !== -1
                          }
                          placeholder="Position"
                        />
                      )}
                      {candidateReferee.contactNo && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "referee")}
                          value={newReferee.contactNo || ""}
                          className="col-md-4"
                          name="contactNo"
                          type="number"
                          placeholder="Contact No"
                          required={
                            candidateReferee.requiredFields.indexOf(
                              "contactNo"
                            ) !== -1
                          }
                        />
                      )}
                      {candidateReferee.contactEmail && (
                        <TextValidator
                          onChange={(e) => this.onChange(e, "referee")}
                          value={newReferee.contactEmail || ""}
                          className="col-md-4"
                          name="contactEmail"
                          type="email"
                          placeholder="Contact Email"
                          required={
                            candidateReferee.requiredFields.indexOf(
                              "contactemail"
                            ) !== -1
                          }
                        />
                      )}
                    </div>
                    <div style={{ overflowX: "auto" }}>
                      <table className="table">
                        <thead>
                          <tr>
                            {candidateReferee.refereeName && (
                              <th>Referee Name</th>
                            )}
                            {candidateReferee.company && <th>Company</th>}
                            {candidateReferee.position && <th>Position</th>}
                            {candidateReferee.contactNo && <th>Contact No</th>}
                            {candidateReferee.contactEmail && (
                              <th>Contact Email</th>
                            )}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {referee.map((item, id) => (
                            <tr>
                              {candidateReferee.refereeName && (
                                <td>{item.refereeName}</td>
                              )}
                              {candidateReferee.company && (
                                <td>{item.company}</td>
                              )}
                              {candidateReferee.position && (
                                <td>{item.position}</td>
                              )}
                              {candidateReferee.contactNo && (
                                <td>{item.contactNo}</td>
                              )}
                              {candidateReferee.contactEmail && (
                                <td>{item.contactEmail}</td>
                              )}
                              <td>
                                <button
                                  onClick={() => this.removeItem(id, "referee")}
                                  className="btn-link"
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ValidatorForm>
              <hr style={{ background: "#f8f9fa", height: 1 }} />
              <div style={{ flex: 0.2 }}>
                <button
                  id="btt_next"
                  type="submit"
                  className="btn-next"
                  onClick={() => this.onChangeTab("apply")}
                >
                  <span>Next</span>
                </button>
                <button
                  id="btt_next"
                  type="button"
                  className="btn-next"
                  onClick={() => this.onChangeTab("parents-data")}
                >
                  <span>Prev</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          id="declaration"
          className="tab-content row"
          style={{ display: "none" }}
        >
          <div className="col-md-12">
            <div className="content-left">
              <h2>Resume</h2>
              <div className="content-height">
                <ValidatorForm onSubmit={() => this.onChangeTab("apply")}>
                  {candidateUpload.resumeURL && (
                    <React.Fragment>
                      <div className="row">
                        <FileUpload
                          className="col-md-4"
                          type="file"
                          name="resumeURL"
                          accept=".pdf,.doc"
                          onChange={(e) => this.onChangeResume(e)}
                          required={
                            candidateUpload.requiredFields.indexOf(
                              "resumeURL"
                            ) !== -1
                          }
                        />
                      </div>
                      {upload.resumeURL ? (
                        <div className="row">
                          <div className="col-md-4">
                            <button
                              type="button"
                              className="btn-link"
                              onClick={(e) => this.onResumeDownload(e)}
                            >
                              Download Resume
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  )}
                </ValidatorForm>
              </div>
              <hr style={{ background: "#f8f9fa", height: 1 }} />

              <div style={{ flex: 0.2 }}>
                <button
                  id="btt_next"
                  type="submit"
                  className="btn-next"
                  onClick={() => this.onChangeTab("apply")}
                >
                  <span>Next</span>
                </button>
                <button
                  id="btt_next"
                  type="button"
                  className="btn-next"
                  onClick={() => this.onChangeTab("other-info")}
                >
                  <span>Prev</span>
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <div id="apply" className="tab-content row" style={{ display: "none" }}>
          <div className="col-md-12">
            <div className="content-left">
              <h2>Apply</h2>
              <ValidatorForm
                ref={(ref) => {
                  this.declarationform = ref;
                }}
                onSubmit={this.onApply}
              >
                <div className="content-height">
                  <div>
                    <h5 style={{ fontSize: 18 }}>Candidate informations:</h5>
                    <div className="row col-md-12" style={{ marginBottom: 20 }}>
                      {candidateProfile.title && (
                        <TextValidator
                          value={profile.title}
                          className="col-md-4"
                          name="title"
                          placeholder="Title"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.firstname && (
                        <TextValidator
                          value={profile.firstName}
                          className="col-md-4"
                          name="firstName"
                          placeholder="First Name"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.lastname && (
                        <TextValidator
                          value={profile.lastName}
                          className="col-md-4"
                          name="lastName"
                          placeholder="Last Name"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.dob && (
                        <TextValidator
                          value={profile.dob}
                          className="col-md-4"
                          name="dob"
                          placeholder="DOB"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.email && (
                        <TextValidator
                          value={profile.email}
                          className="col-md-4"
                          name="email"
                          placeholder="Email"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.primaryContactno && (
                        <TextValidator
                          value={profile.primaryContactno}
                          className="col-md-4"
                          name="primaryContactno"
                          placeholder="Primary Contact no"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.address && (
                        <TextAreaValidator
                          value={profile.address}
                          className="col-md-4"
                          name="address"
                          placeholder="Address"
                          disabled={true}
                        />
                      )}

                      {candidateProfile.marital && (
                        <TextValidator
                          value={profile.marital}
                          className="col-md-4"
                          name="marital"
                          placeholder="Martial"
                          disabled={true}
                        />
                      )}

                      {candidateProfile.nationality && (
                        <TextValidator
                          value={profile.nationality}
                          className="col-md-4"
                          name="nationality"
                          placeholder="Nationality"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.educationLevel && (
                        <TextValidator
                          value={profile.educationLevel}
                          className="col-md-4"
                          name="educationLevel"
                          placeholder="Education Level"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.identifier && (
                        <TextValidator
                          value={profile.identifierType}
                          className="col-md-4"
                          name="identifierType"
                          placeholder="ID Type"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.identifier && (
                        <TextValidator
                          value={profile.identifierNo}
                          className="col-md-4"
                          name="identifierNo"
                          placeholder="ID Number"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.workExp && (
                        <TextValidator
                          value={profile.workExp}
                          className="col-md-4"
                          name="workExp"
                          placeholder="Work Experience"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.latestPosition && (
                        <TextValidator
                          value={profile.latestPosition}
                          className="col-md-4"
                          name="latestPosition"
                          placeholder="Latest Position"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.latestSalary && (
                        <TextValidator
                          value={profile.latestSalaryCur}
                          className="col-md-4"
                          name="latestSalaryCur"
                          placeholder="Latest Salary Currency"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.latestSalary && (
                        <TextValidator
                          value={profile.latestSalary}
                          className="col-md-4"
                          type="number"
                          name="latestSalary"
                          placeholder="Latest Salary"
                          disabled={true}
                        />
                      )}

                      {candidateProfile.latestCareerLevel && (
                        <TextValidator
                          value={profile.latestCareerLevel}
                          className="col-md-4"
                          name="latestCareerLevel"
                          placeholder="Latest Career Level"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.expectedSalary && (
                        <TextValidator
                          value={profile.expectedSalaryCur}
                          className="col-md-4"
                          name="expectedSalaryCur"
                          placeholder="Expected Salary Currency"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.expectedSalary && (
                        <TextValidator
                          value={profile.expectedSalary}
                          className="col-md-4"
                          type="number"
                          name="expectedSalary"
                          placeholder="Expected Salary"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.availability && (
                        <TextValidator
                          value={profile.availabilityType}
                          className="col-md-4"
                          name="availabilityType"
                          placeholder="Availability Type"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.availability && (
                        <TextValidator
                          value={profile.availability}
                          className="col-md-4"
                          name="availability"
                          placeholder="Availability"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.careerObjective && (
                        <TextAreaValidator
                          value={profile.careerObjective}
                          className="col-md-4"
                          name="careerObjective"
                          placeholder="Career Objective"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.skills && (
                        <TextValidator
                          value={profile.skills}
                          className="col-md-4"
                          name="skills"
                          placeholder="Skills"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.jobFunctions && (
                        <TextValidator
                          value={profile.jobFunctions}
                          className="col-md-4"
                          name="jobFunctions"
                          placeholder="Job Functions"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.industries && (
                        <TextValidator
                          value={profile.industries}
                          className="col-md-4"
                          name="industries"
                          placeholder="Industries"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.location && (
                        <TextValidator
                          value={profile.location}
                          className="col-md-4"
                          name="location"
                          placeholder="Location"
                          disabled={true}
                        />
                      )}

                      {candidateProfile.postalcode && (
                        <TextValidator
                          value={profile.postalCode}
                          className="col-md-4"
                          name="postalCode"
                          placeholder="Postal Code"
                          disabled={true}
                        />
                      )}
                      {candidateProfile.country && (
                        <TextValidator
                          value={profile.country}
                          className="col-md-4"
                          name="country"
                          placeholder="Country"
                          disabled={true}
                        />
                      )}
                    </div>
                    <h5 style={{ fontSize: 18 }}>Education:</h5>
                    <div style={{ overflow: "auto", marginBottom: 20 }}>
                      <table className="table">
                        <thead style={{ fontSize: 14 }}>
                          <tr>
                            {candidateEducation.qualification && (
                              <th>Qualification</th>
                            )}
                            {candidateEducation.institution && (
                              <th>Institution</th>
                            )}
                            {candidateEducation.field && <th>Field</th>}
                            {candidateEducation.grade && <th>Grade</th>}
                            {candidateEducation.specialAchievement && (
                              <th>Special Achievement</th>
                            )}
                            {candidateEducation.period && <th>From</th>}
                            {candidateEducation.period && <th>To</th>}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {education.length !== 0 ? (
                            education.map((item, id) => (
                              <tr>
                                {candidateEducation.qualification && (
                                  <td>
                                    {item.qualification !== null &&
                                      item.qualification !== ""
                                      ? item.qualification
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateEducation.institution && (
                                  <td>
                                    {item.institution !== null &&
                                      item.institution !== ""
                                      ? item.institution
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateEducation.field && (
                                  <td>
                                    {item.field !== null && item.field !== ""
                                      ? item.field
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateEducation.grade && (
                                  <td>
                                    {item.grade !== null && item.grade !== ""
                                      ? item.grade
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateEducation.specialAchievement && (
                                  <td>
                                    {item.specialAchievement !== null &&
                                      item.specialAchievement !== ""
                                      ? item.specialAchievement
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateEducation.period && (
                                  <td>
                                    {item.periodFrom !== null &&
                                      item.periodFrom !== ""
                                      ? item.periodFrom
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateEducation.period && (
                                  <td>
                                    {item.periodTo !== null &&
                                      item.periodTo !== ""
                                      ? item.periodTo
                                      : "N/A"}
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <p style={{ marginTop: 15, marginBottom: 10 }}>
                              No Education Data
                            </p>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <h5 style={{ fontSize: 18 }}>Certificate:</h5>
                    <div style={{ overflow: "auto", marginBottom: 20 }}>
                      <table className="table">
                        <thead style={{ fontSize: 14 }}>
                          <tr>
                            {candidateCertificate.certificateName && (
                              <th>Certificate Name</th>
                            )}
                            {candidateCertificate.issuedBy && (
                              <th>Issued By</th>
                            )}
                            {candidateCertificate.yearObtained && (
                              <th>Year Obtained</th>
                            )}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {certificate.length !== 0 ? (
                            certificate.map((item, id) => (
                              <tr>
                                {candidateCertificate.certificateName && (
                                  <td>
                                    {item.certificateName !== null &&
                                      item.certificateName !== ""
                                      ? item.certificateName
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateCertificate.issuedBy && (
                                  <td>
                                    {item.issuedBy !== null &&
                                      item.issuedBy !== ""
                                      ? item.issuedBy
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateCertificate.yearObtained && (
                                  <td>
                                    {item.yearObtained !== null &&
                                      item.yearObtained !== ""
                                      ? item.yearObtained
                                      : "N/A"}
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <p style={{ marginTop: 15, marginBottom: 10 }}>
                              No Certificate Data
                            </p>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <h5 style={{ fontSize: 18 }}>Experience:</h5>
                    <div style={{ overflow: "auto", marginBottom: 20 }}>
                      <table className="table">
                        <thead style={{ fontSize: 14 }}>
                          <tr>
                            {candidateExperence.company && <th>Company</th>}
                            {candidateExperence.position && <th>Position</th>}
                            {candidateExperence.salaryCurrency && (
                              <th>Currency</th>
                            )}
                            {candidateExperence.salary && <th>Salary</th>}
                            {candidateExperence.jobDuties && (
                              <th>Job Duties</th>
                            )}
                            {candidateExperence.period && <th>From Month</th>}
                            {candidateExperence.period && <th>From Year</th>}
                            {candidateExperence.period && <th>To Month</th>}
                            {candidateExperence.period && <th>To Year</th>}
                            {candidateExperence.period && <th>Present</th>}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {experience.length !== 0 ? (
                            experience.map((item, id) => (
                              <tr>
                                {candidateExperence.company && (
                                  <td>
                                    {item.company !== null &&
                                      item.company !== ""
                                      ? item.company
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateExperence.position && (
                                  <td>
                                    {item.position !== null &&
                                      item.position !== ""
                                      ? item.position
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateExperence.salaryCurrency && (
                                  <td>
                                    {item.salaryCurrency !== null &&
                                      item.salaryCurrency !== ""
                                      ? item.salaryCurrency
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateExperence.salary && (
                                  <td>
                                    {item.salary !== null && item.salary !== ""
                                      ? item.salary
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateExperence.jobDuties && (
                                  <td>
                                    {item.jobDuties !== null &&
                                      item.jobDuties !== ""
                                      ? item.jobDuties
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateExperence.period && (
                                  <td>
                                    {item.fromMonth !== null &&
                                      item.fromMonth !== ""
                                      ? item.fromMonth
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateExperence.period && (
                                  <td>
                                    {item.fromYear !== null &&
                                      item.fromYear !== ""
                                      ? item.fromYear
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateExperence.period && (
                                  <td>
                                    {item.toMonth !== null &&
                                      item.toMonth !== ""
                                      ? item.toMonth
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateExperence.period && (
                                  <td>
                                    {item.toYear !== null && item.toYear !== ""
                                      ? item.toYear
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateExperence.period && (
                                  <td>
                                    {item.present.toString() !== null &&
                                      item.present.toString() !== ""
                                      ? item.present.toString()
                                      : "N/A"}
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <p style={{ marginTop: 15, marginBottom: 10 }}>
                              No Experience Data
                            </p>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <h5 style={{ fontSize: 18 }}>Referee Data:</h5>
                    <div style={{ overflowX: "auto", marginBottom: 20 }}>
                      <table className="table">
                        <thead style={{ fontSize: 14 }}>
                          <tr>
                            {candidateReferee.refereeName && (
                              <th>Referee Name</th>
                            )}
                            {candidateReferee.company && <th>Company</th>}
                            {candidateReferee.position && <th>Position</th>}
                            {candidateReferee.contactNo && <th>Contact No</th>}
                            {candidateReferee.contactEmail && (
                              <th>Contact Email</th>
                            )}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {referee.length !== 0 ? (
                            referee.map((item, id) => (
                              <tr>
                                {candidateReferee.refereeName && (
                                  <td>
                                    {item.refereeName !== null &&
                                      item.refereeName !== ""
                                      ? item.refereeName
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateReferee.company && (
                                  <td>
                                    {item.company !== null &&
                                      item.company !== ""
                                      ? item.company
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateReferee.position && (
                                  <td>
                                    {item.position !== null &&
                                      item.position !== ""
                                      ? item.position
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateReferee.contactNo && (
                                  <td>
                                    {item.contactNo !== null &&
                                      item.contactNo !== ""
                                      ? item.contactNo
                                      : "N/A"}
                                  </td>
                                )}
                                {candidateReferee.contactEmail && (
                                  <td>
                                    {item.contactEmail !== null &&
                                      item.contactEmail !== ""
                                      ? item.contactEmail
                                      : "N/A"}
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <p style={{ marginTop: 15, marginBottom: 10 }}>
                              No Referee Data
                            </p>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <h5 style={{ fontSize: 18 }}>Resume:</h5>
                    <div className="col-md-4" style={{ marginBottom: 50 }}>
                      <button
                        type="button"
                        className="btn-link"
                        onClick={(e) => this.onResumeDownload(e)}
                      >
                        <span>Click here to view uploaded resume</span>
                      </button>
                    </div>
                  </div>

                  <CheckBoxValidator
                    required
                    validators={["required"]}
                    invalidMessage="Please read and check to proceed"
                    name="declaration"
                    value={this.state.declaration}
                    text={
                      "I hereby that the information in this application is correct and accurate."
                    }
                    onChange={(e) =>
                      this.setState({ declaration: e.target.checked })
                    }
                  />
                </div>
                <hr style={{ background: "#f8f9fa", height: 1 }} />
                <button type="submit" id="btt_next" className="btn-next">
                  <span>Apply</span>
                </button>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeMaster,
      storeProfilePicture,
      goto: (e, data) => push({ pathname: e, state: { data } })
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  user: state.user.user,
  subscription: state.user.subscription,
  master: state.user.master,
  jobposition: state.user.jobposition,
  profilePicture: state.user.profilePicture,
});
export default connect(mapStateToProps, mapDispatchToProps)(ApplyContent);
