import React from "react";
import Modal from 'react-responsive-modal';
import { PropagateLoader } from 'react-spinners';

const Loader = ({ loading, text }, ...rest) => (
    <Modal open={loading} styles={{
        modal: {
            boxShadow: 'none',
            backgroundColor: 'transparent', display: 'flex',
            flexDirection: 'column', alignItems: 'center'
        }
    }} center closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={false}>
        <div style={{ height: 20 }}>
            <PropagateLoader color={'#ffff'}
                loading={loading} />
        </div>

        <p style={{ color: '#ffff' }}>{text}</p>

    </Modal>
)
export default Loader;