// IMPORT PACKAGE REFERENCES
import React, { Component } from "react";
import {
  storeSubscription,
  storeMaster,
  storeJobPosition,
} from "../_reducers/reducer.user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { errorNotification } from "../_reducers/reducer.notification";
import Header from "../components/Header/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../_api";
import { devLog } from "../_helper/helper.methods";

//Language

class HomePage extends Component {
  constructor(props) {
    super(props);
    //let { password: { userUID } } = props.user
    this.state = {
      step: 0,
      open: false,
      imgs: [],
      order: null,
      header_title: null,
      button_tilte: "Apply",
      Fees: null,
      loading: false,
      languages: [1, 2],
      joblist: [],
    };
  }

  onApplyClick = (obj, ev) => {
    devLog("obj", obj.candidateFields);

    //let islogin = this.props.user;
    this.props.storeJobPosition(obj);
    document.getElementById("btn_apply").click();
    //ev.preventDefault();
  };

  async componentDidMount() {
    let { match } = this.props;
    let subid = match.params.subid;
    try {
      let res = await API.Login.GetJobPosition(subid);
      if (res.status !== "1") {
        let subscription = {
          logo: res.subscription.subscriptionLogoURL,
          subscription: subid,
          subscriptionName: res.subscription.subscriptionName,
        };
        this.props.storeSubscription(subscription);
        this.handleJobPostionList(res.jobList);
      } else {
        // toast.error(res.message, { autoClose: true })
      }

      let masterRes = await API.Master.CoreList(subid);
      if (masterRes.status !== "1") {
        let mlist = {
          ...masterRes,
          modulemaster: {
            ...this.props.master.modulemaster,
          },
        };
        this.props.storeMaster(mlist);
      } else {
        // toast.error(res.message, { autoClose: true })
      }
    } catch (error) {
      this.props.history.push('/');
      this.setState({ noJobs: true });
      toast.error(
        "No Jobs available at present. Please check out after some time."
      );
    }
  }

  handleJobPostionList = (jobList) => {
    let {
      params: { jobPosUID },
    } = this.props.match;
    let matchedJob;
    if (jobPosUID) {
      jobList.some((item) => {
        if (jobPosUID === item.jobPosUID) {
          matchedJob = item;
          jobList = [item];
          return true;
        } else {
          return false;
        }
      });
      this.setState({
        joblist: jobList,
      });
      if (matchedJob) {
        this.onApplyClick(matchedJob);
      }
    } else {
      this.setState({
        joblist: jobList,
      });
    }
  };

  render() {
    let joblist = this.state.joblist;
    const { /*Campus,*/ noJobs } = this.state;
    return (
      <div>
        <Header {...this.props} />
        <main className="animated fadeIn">
          <div className="container" style={{ marginTop: 100 }}>
            <section id="content">
              <ToastContainer />
              <div id="university" className="tab-content row">
                <div className="col-md-12">
                  <div className="content-left">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h2>Jobs</h2>
                    </div>
                    <div>
                      {/* className="content-height" */}
                      {joblist.map((data, ind) => (
                        <React.Fragment key={data.jobPosUID}>
                          <div
                            style={{
                              margin: 5,
                              padding: 1,
                              width: "100%",
                              height: "1px",
                              background: "rgba(0, 0, 0, 0.1)",
                            }}
                          />
                          <div
                            className="row"
                            style={{ overflowY: "auto", maxheight: 200 }}
                          >
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="lib-row lib-header">
                                    <strong> {data.jobTitle}</strong>
                                    <div className="lib-header-seperator"></div>
                                  </div>
                                  <div className="lib-row lib-desc">
                                    {/*data.description*/}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: data.description,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            id="btt_next"
                            type="button"
                            className="btn"
                            onClick={(ev) => this.onApplyClick(data, ev)}
                          >
                            <span>Apply</span>
                          </button>

                          {/* <ButtonLogin id="btn_apply" style={{display:"none"}} title="Sign In" goto={(path, from) => {
                                                        this.props.history.push({ pathname: path, state: { from } })
                                                    }}
                                                        newlogin='0' status="student" /> */}
                          <br />
                        </React.Fragment>
                      ))}

                      {joblist.length === 0 &&
                        "No Jobs available at present. Please check out after some time."}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      errorNotification,
      storeSubscription,
      storeMaster,
      storeJobPosition,
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  user: state.user.user,
  subscription: state.user.subscription,
  master: state.user.master,
});
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
