import React from "react";
import SelectValidator from '../../components/Validators/SelectValidator'
import TextValidator from '../../components/Validators/TextValidator';

class EducationDetail extends React.Component {
    componentDidMount() {
        this.setState({
            student: this.props.student,
            education: this.props.education
        })
    }
    constructor(props) {
        super(props);
        this.state = {
            student: {},
            education: {},
            educationAttachment: {
                uid: "",
                userUID: "",
                studentEducationUID: '',
                attachType: '',
                attachURL: ''
            }
        }
    };
    onChangeEducation = (event, index) => {
        let student = this.state.student;
        let education = this.state.education;
        if (event.target.name === "attachType") {
            let educationAttchDetail = education.educationAttachment[index];
            educationAttchDetail = {
                ...educationAttchDetail,
                [event.target.name]: event.target.value
            }
            education.educationAttachment[index] = educationAttchDetail;
            student.education[this.props.index] = education;
            this.setState({
                student,
                education
            })
        } else if (event.target.name === "attachURL") {
            let educationAttchDetail = education.educationAttachment[index];
            educationAttchDetail = {
                ...educationAttchDetail,
                [event.target.name]: event.target.files[0]
            }
            education.educationAttachment[index] = educationAttchDetail;
            student.education[this.props.index] = education;
            this.setState({
                student,
                education
            })
        } else {
            education = {
                ...education,
                [event.target.name]: event.target.value
            }
            student.education[this.props.index] = education;
            this.setState({
                student,
                education
            })
        }
        this.props.updatestate(student);

    };
    AddEducationAttachRow = (event) => {
        let education = this.state.education;
        let student = this.state.student;
        student.education[this.props.index].educationAttachment.push(this.state.educationAttachment);
        this.setState({
            student,
            education
        })
        this.props.updatestate(student);
    }

    render() {
        //let { ...rest } = this.props;
        //let { ...state } = this.props.state;
        let master = this.props.master;
        let index = this.props.index;
        //let photoUrl = null;
        //let defaultimage = "https://www.1plusx.com/app/mu-plugins/all-in-one-seo-pack-pro/images/default-user-image.png";
        let education = this.state.education;

        return (
            <div>
                <div className="row">
                    <SelectValidator
                        onClose={this.props.onBlur}
                        name="qualification"
                        className="col-md-4"
                        onSelect={(ev) => {
                            this.onChangeEducation(ev)
                        }}
                        data={master.programTypeList ? master.programTypeList.map(({ text }) => text) : []}
                        options={{ placeholder: "Choose Program" }}
                        value={education.qualification}
                    />
                    <TextValidator
                        onBlur={this.props.onBlur}
                        onChange={(ev) => this.onChangeEducation(ev, index)}
                        value={education.institution}
                        className="col-md-4"
                        name="institution"
                        placeholder="Institution"
                    />
                    <TextValidator
                        onBlur={this.props.onBlur}
                        onChange={(ev) => this.onChangeEducation(ev, index)}
                        value={education.field}
                        className="col-md-4"
                        name="field"
                        placeholder="Field"
                    />
                </div>
                <div className="row">
                    <TextValidator
                        onBlur={this.props.onBlur}
                        onChange={(ev) => this.onChangeEducation(ev, index)}
                        value={education.grade}
                        className="col-md-4"
                        name="grade"
                        placeholder="Grade"
                    />
                    <TextValidator
                        onBlur={this.props.onBlur}
                        onChange={(ev) => this.onChangeEducation(ev, index)}
                        value={education.specialAchievement}
                        className="col-md-4"
                        name="specialAchievement"
                        placeholder="SpecialAchievement"
                    />
                    <TextValidator
                        onBlur={this.props.onBlur}
                        onChange={(ev) => this.onChangeEducation(ev, index)}
                        value={education.periodFrom}
                        className="col-md-4"
                        name="periodFrom"
                        placeholder="PeriodFrom"
                        type="date"
                    />
                </div>
                <div className="row">
                    <TextValidator
                        onBlur={this.props.onBlur}
                        onChange={(ev) => this.onChangeEducation(ev, index)}
                        value={education.periodTo}
                        className="col-md-4"
                        name="periodTo"
                        placeholder="PeriodTo"
                        type="date"
                    />
                </div>
                <div className="row">
                    {education.educationAttachment && education.educationAttachment.map((dt, i) => (
                        <div key={i}>
                            <div className="col-md-12">
                                <SelectValidator
                                    onClose={this.props.onBlur}
                                    label="AttachType"
                                    name="attachType"
                                    onSelect={(ev) => this.onChangeEducation(ev, i)}
                                    data={master.educationAttachmentTypeList ? master.educationAttachmentTypeList.map(({ text }) => text) : []}
                                    options={{ placeholder: "Choose Qualification" }}
                                    value={dt.attachType}
                                />
                                <input onChange={(ev) => this.onChangeEducation(ev, i)} type="file" name="attachURL" className="form-control" />
                            </div>
                        </div>
                        // <FileUpload key={i} {...this.props} state={this.state} attachdata={dt} attachindex={i} updatestate={(stud) => this.setState({ student: stud })} />
                    ))}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button type="button" style={{ padding: 2 }} className="btn" onClick={(ev) => this.AddEducationAttachRow(ev)} ><span>Add Attachment</span></button>
                    </div>
                </div>
                <hr style={{ width: '100%', height: '1px', background: '#ffff' }} />
            </div>
        )
    }
}

export default EducationDetail;