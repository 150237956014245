import React from 'react';

import { Route } from 'react-router-dom';
//comp
import WelcomeBack from './Welcomeback';
import JobPosition from './JobPosition';
import AppliedList from './AppliedList';
import ChangePassword from './ChangePassword';

class WelcomeView extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route exact path={`${match.path}/welcome`} component={WelcomeBack} />
                <Route exact path={`${match.path}/jobposition`} component={JobPosition} />
                <Route exact path={`${match.path}/jobapplied`} component={AppliedList} />
                <Route exact path={`${match.path}/changepassword`} component={ChangePassword} />
                
            </React.Fragment >
        );
    }
}
export default WelcomeView;