// IMPORT PACKAGE REFERENCES

import React, { Component } from "react";
import Modal from "react-responsive-modal";
import PropTypes from "prop-types";
import { storeUser } from "../../_reducers/reducer.user";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { errorNotification } from "../../_reducers/reducer.notification";

import SelectValidator from "../../components/Validators/SelectValidator";
import TextValidator from "../../components/Validators/TextValidator";
import { ValidatorForm } from "react-form-validator-core";

import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../../_api";

import { formDataConsole, devLog } from "../../_helper/helper.methods";
// COMPONENT
class ButtonLogin extends Component {
  constructor(props) {
    super(props);
    this.statusform = 0;
    this.state = {
      signupSuccess: "",
      loading: false,
      open: false,
      email: "",
      password: "",
      continueform: false,
      textbutton: "continue",
      h2button: "Sign In",
      is_student: false,
      statusform: 0,
      token: "",
      userUID: "",
      signupDetails: this.SignUp(),
    };
  }
  SignUp = () => {
    return {
      title: "",
      firstName: "",
      lastName: "",
      gender: "",
      mobile: "",
      email: "",
    };
  };
  componentDidMount() {
    //let pp = this.props;
  }
  onOpenModal = () => {
    devLog(this.props.status);
    if (this.props.status === "student") {
      this.setState({ statusform: 0 });
      this.setState({ open: true });
      this.setState({ email: "" });
      this.setState({ continueform: false });
      this.setState({ is_student: true });
    } else if (this.props.status === "signup") {
      this.props.goto("/signup");
    }
  };
  onChangeLink = (boolvalue, _statusform) => {
    if (document.getElementById("txt_id") !== null) {
      document.getElementById("txt_id").style.display = "block";
    }

    this.setState({ continueform: boolvalue });
    if (_statusform !== undefined) {
      this.setState({ statusform: _statusform });
    }
  };
  onCloseModal = () => {
    this.setState({ open: false });
    this.setState({ h2button: "Sign In" });
    this.setState({ textbutton: "continue" });
  };
  onResetPassword = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let { userUID, token, email } = this.state;
    let formData = new FormData();
    formData.append("profileUID", userUID);
    formData.append("email", email);
    formData.append("subscription", this.props.subscription.subscription);

    Api.Login.forgetPassword(token, formData).then((res) => {
      this.setState({ loading: false });
      if (res.status !== "1") {
        this.onChangeLink(true, 3);
      } else {
        // toast.error(res.message, { autoClose: true })
      }
    });
  };
  onSubmit = (ev) => {
    ev.preventDefault();
    //let type = this.props.status;

    if (this.state.statusform === 0) {
      if (this.state.textbutton.toLocaleLowerCase() === "continue") {
        devLog(document.getElementById("txt_password").value);
        if (this.state.email !== "") {
          const data = new FormData(ev.target);
          this.setState({ loading: true });
          Api.Login.Email(data, this.props.subscription.subscription).then(
            (res) => {
              this.setState({ loading: false });
              if (res.status !== "1") {
                document.getElementById("txt_password").style.display = "block";
                document.getElementById("txt_id").style.display = "none";
                document.getElementById("txt_password").required = true;
                this.setState({
                  continueform: true,
                  textbutton: "Sign In",
                  h2button: "Welcome",
                  token: res.token,
                  userUID: res.profileUID,
                });
              } else {
                this.setState({ error: res.message });
                toast(res.message, { autoClose: true });
              }
            }
          );
        }
      } else if (this.state.textbutton.toLocaleLowerCase() === "sign in") {
        if (
          this.state.email !== "" &&
          document.getElementById("txt_password").value !== ""
        ) {
          //let pp = this.state;
          this.setState({ loading: true });

          const data = new FormData(ev.target);
          //data.append('userID', this.state.email);
          data.append("actionBy", this.state.userUID);
          data.append("token", this.state.token);

          Api.Login.Password(data, this.props.subscription.subscription).then(
            (res) => {
              this.setState({ loading: false });
              if (res.status !== "1") {
                let user = {
                  profileUID: res.profileUID,
                  token: res.token,
                  password: res,
                  from: "signin",
                };
                this.props.storeUser(user);
                let { jobposition } = this.props;
                if (jobposition.jobTitle) {
                  this.props.goto("/applypage/apply");
                } else {
                  this.props.goto("/applypage/welcome");
                }
              } else {
                this.setState({ error: res.message });
                toast(res.message, { autoClose: true });
              }
            }
          );
        }
      }
    } else if (this.state.statusform === 2) {
      const data = new FormData(ev.target);
      formDataConsole(data);
      if (
        data.get("encPassword").toLowerCase() ===
        data.get("confirmpassword").toLowerCase()
      ) {
        Api.Login.SignUp(data, this.props.subscription.subscription).then(
          (res) => {
            this.setState({
              loading: false,
              submitted: false,
            });
            if (res !== null) {
              toast("SignUp successfull!", { autoClose: true });
              res.email = this.state.email;
              let user = {
                profileUID: res.profileUID,
                token: res.token,
                password: res,
              };
              this.props.storeUser(user);
              let { jobposition } = this.props;
              if (jobposition.jobTitle) {
                this.props.goto("/applypage/apply");
              } else {
                this.props.goto("/applypage/welcome");
              }
            } else {
              this.setState({ error: res.message });
              toast(res.message, { autoClose: true });
            }
          }
        );
      } else {
        alert("Please check again your password!");
      }
    }
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };
  onSignUpChange = (event) => {
    let signupDetails = this.state.signupDetails;
    this.setState({
      signupDetails: {
        ...signupDetails,
        [event.target.name]: event.target.value,
      },
    });
  };
  renderForm = () => {
    const onEmailChange = (ev) =>
      this.onChange("email", ev.target.value); /*eslint-disable no-unused-vars*/
    let textbutton = this.state.textbutton;
    let { is_student, signupDetails } = this.state;
    let master = this.props.master;
    switch (this.state.statusform) {
      case 0: // Login
        return (
          <form className="login_form-wrapper" onSubmit={this.onSubmit}>
            <h2>{this.state.h2button}</h2>
            {this.state.continueform && this.email !== ""
              ? this.state.email
              : ""}
            <input
              id="txt_id"
              type="email"
              required
              className={`email ${this.props.status}`}
              name="userId"
              placeholder="Email"
              value={this.state.email}
              onChange={onEmailChange}
            />
            <input
              id="txt_password"
              style={{ display: "none" }}
              type="password"
              className={`password ${this.props.status}`}
              name="userPassword"
              placeholder="Password"
            />
            {this.state.continueform && (
              <div className="row">
                {/*eslint-disable no-script-url*/}
                <div className="col-md-6">
                  <input id="txt_keepsignin" type="checkbox" />
                  Keep Sign In
                </div>
                <div className="col-md-6">
                  <a
                    href="javascript:;"
                    id="txt_forgot"
                    onClick={() => this.onChangeLink(false, 1)}
                  >
                    Forgot Password
                  </a>
                </div>
              </div>
            )}
            <button id="btt_submit" className="btn-sign" type="submit">
              <span>{this.state.textbutton}</span>
            </button>
            <div className="line-heading">New to SARVAM?</div>
            <div
              className="btn-sign"
              onClick={() => this.onChangeLink(false, 2)}
            >
              <span>sign up</span>
            </div>
          </form>
        );

      case 1: // Forgot Password
        return (
          <form className="login_form-wrapper" onSubmit={this.onResetPassword}>
            <h2>Forgot Password</h2>
            <p>Do not worry</p>
            <input
              id="txt_forgotpassword"
              type="email"
              required
              className={`email ${this.props.status}`}
              name="Specify your email"
              placeholder="Email"
              value={this.state.email}
              onChange={onEmailChange}
            />
            <button className="btn-sign" type="submit">
              <span>reset password</span>
            </button>
          </form>
        );
      case 2: // Sign Up
        return (
          <form className="login_form-wrapper">
            <h2>Sign Up</h2>
            {/* <input id="txt_name" type="text" required className={`name ${this.props.status}`} name={is_student ? "firstName" : 'universityName'} placeholder={this.state.is_student ? 'Name' : 'University Name'} />
                    <input id="txt_email" type="email" required className={`email ${this.props.status}`} name="email" placeholder={this.state.is_student ? 'Email Address' : 'Work Email Address'} value={this.state.email} onChange={onEmailChange} />
                    {!is_student &&
                        <React.Fragment>
                            <input type="number" required name="phone" placeholder='Phone' />
                            <textarea type="text" name='notes' placeholder='Notes' />
                            <textarea type="text" name='address' placeholder='Address' />

                        </React.Fragment>}
                    <input id="txt_password" type="password" required className={`password ${this.props.status}`} name="password" placeholder="Password" />
                    <input id="txt_confirmpassword" type="password" required className={`confirmpassword ${this.props.status}`} name="confirmpassword" placeholder="Confirm Password" /> */}
            <ValidatorForm
              ref={(ref) => {
                this.personalform = ref;
              }}
              onSubmit={this.onSubmit}
            >
              <SelectValidator
                // onChange={this.onSignUpChange}
                name="title"
                required
                data={master.salutationList.map(({ text }) => text)}
                options={{ placeholder: "Title" }}
              />
              <TextValidator
                // onChange={this.onSignUpChange}
                name="firstName"
                required
                placeholder="First Name"
              />
              <TextValidator
                // onChange={this.onSignUpChange}
                name="lastName"
                required
                placeholder="Last Name"
              />

              <SelectValidator
                // onChange={this.onSignUpChange}
                name="gender"
                required
                data={master.genderList.map(({ text }) => text)}
                options={{ placeholder: "Gender" }}
              />
              <TextValidator
                //onChange={this.onSignUpChange}
                //value={signupDetails.email}
                name="email"
                type="email"
                required
                placeholder="Email"
              />
              <TextValidator
                //onChange={this.onSignUpChange}
                //value={signupDetails.mobile}
                type="number"
                required
                name="mobile"
                placeholder="Mobile"
              />
              <TextValidator
                type="password"
                required
                name="encPassword"
                placeholder="Password"
              />
              <TextValidator
                type="password"
                required
                name="confirmpassword"
                placeholder="Confirm Password"
              />
              {this.state.signupSuccess && <p>{this.state.signupSuccess}</p>}
              <button id="btt_signup" className="btn-sign">
                <span>sign up</span>
              </button>
            </ValidatorForm>
          </form>
        );
      case 3: // Forgot Password Success
        return (
          <form
            className="login_form-wrapper"
            onSubmit={(ev) => this.onForgotPassword(ev)}
          >
            <h2>Reset Password Link</h2>
            <p>Send to {this.state.email !== "" ? this.state.email : ""}</p>
            <p>Follow the instruction</p>
            <button
              id="btt_close"
              className="btn-sign"
              onClick={() => window.close()}
            >
              <span>Close</span>
            </button>
          </form>
        );
      case 4: // Forgot Password Failed
        return (
          <form
            className="login_form-wrapper"
            onSubmit={(ev) => this.onSubmit(ev)}
          >
            <h2>Oops!</h2>
            <p>
              We do not recognise{" "}
              {this.state.email === "" ? this.state.email : ""}
            </p>
            <input
              id="txt_forgotpassword"
              type="email"
              required
              className={`email ${this.props.status}`}
              name="Specify your email"
              placeholder="Email"
              value={this.state.email}
              onChange={onEmailChange}
            />
            <button id="btt_submit" className="btn-sign" type="submit">
              <span>Try Again</span>
            </button>
          </form>
        );
      /* no default */
    }
  };

  render() {
    const { open } = this.state;
    const { status } = this.props;

    return (
      <div>
        <button id="btn_apply" onClick={this.onOpenModal}>
          {this.props.title}
        </button>
        <Loader loading={this.state.loading} text="Please Wait...." />
        <ToastContainer />
        <Modal
          open={open}
          onClose={this.onCloseModal}
          center
          styles={{
            modal: { backgroundColor: status === "student" ? "#fff" : "#fff" },
          }}
        >
          <ToastContainer />
          {this.renderForm()}
        </Modal>
      </div>
    );
  }
}

ButtonLogin.defaultProps = {
  title: "",
  status: "",
  newlogin: "0",
  email: "",
  textbutton: "continue",
  h2button: "Sign In",
  history: "",
};

ButtonLogin.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  statusform: PropTypes.number,
  newlogin: PropTypes.string,
  history: PropTypes.string,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      errorNotification,
      storeUser,
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  user: state.user.user,
  master: state.user.master,
  jobposition: state.user.jobposition,
  subscription: state.user.subscription,
});
export default connect(mapStateToProps, mapDispatchToProps)(ButtonLogin);
