// IMPORT PACKAGE REFERENCES
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// COMPONENT
class ApplyHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0
        };
    }
    onChangeTab=(obj,num)=>
    {
        for(var index=0;index< document.getElementsByClassName('tab-content').length;index++){
            document.getElementsByClassName('tab-content')[index].style.display='none';
        }
      
        document.getElementById(obj).style.display='flex';
    };
    render()
    {
       
        return(    
            <header className="tabs-bar">
                <ul className="tabs-lists">
                    <li><NavLink to="#introduction" onClick={() => this.onChangeTab('introduction',1)} className="tab" exact={true}>{this.props.t('LoginIntro')}</NavLink></li>
                    <li><NavLink to="#university" onClick={() => this.onChangeTab('university',2)} className="tab" exact={true}>university&nbsp;&amp;&nbsp;program</NavLink></li>
                    <li><NavLink to="#personal-data" onClick={() => this.onChangeTab('personal-data',3)} className="tab" exact={true}>personal&nbsp;data</NavLink></li>
                    <li><NavLink to="#parents-data" onClick={() => this.onChangeTab('parents-data',4)} className="tab" exact={true}>parent(s)&nbsp;data</NavLink></li>
                    <li><NavLink to="#education-data" onClick={() => this.onChangeTab('education-data',5)} className="tab" exact={true}>education&nbsp;data</NavLink></li>
                    <li><NavLink to="#other-info" onClick={() => this.onChangeTab('other-info',6)} className="tab" exact={true}>other&nbsp;information</NavLink></li>
                    <li><NavLink to="#declaratioin" onClick={() => this.onChangeTab('declaration',7)} className="tab" exact={true}>declaration</NavLink></li>
                    <li><NavLink to="#apply" onClick={() => this.onChangeTab('apply',8)} className="tab" exact={true}>apply</NavLink></li>
                </ul>
            </header>
        );
    }
}

ApplyHeader.defaultProps = {
    to: '',
    status: ''
};

ApplyHeader.propTypes = {
    to: PropTypes.string,
    status: PropTypes.string,
    statusform:PropTypes.number
};

export default ApplyHeader;