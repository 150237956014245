import React from 'react';
//import { NavLink } from "react-router-dom";

const Footer = () => {
    return !window.location.href.includes('construction') && !window.location.href.includes('admin') ? (
        <footer className="footer">
            <div className="footer-border"></div>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-4">
                        <ul>
                            <li><NavLink to="/aboutus">About Us</NavLink></li>
                            <li><NavLink to="/privacypolicy">Privacy Policy</NavLink></li>
                            <li><NavLink to="/termsofuse">Terms of Use</NavLink></li>
                        </ul>
                    </div> */}
                    <div className="col-md-4">
                        {/* <ul>
                            <li><a href="/aboutus">About Us</a></li>
                            <li><a href="/privacypolicy">Privacy Policy</a></li>
                            <li><a href="/termsofuse">Terms of Use</a></li>
                        </ul> */}
                    </div>
                    <div className="col-md-4">
                        {/* <ul>
                            <li><a href="/aboutus">About Us</a></li>
                            <li><a href="/privacypolicy">Privacy Policy</a></li>
                            <li><a href="/termsofuse">Terms of Use</a></li>
                        </ul> */}
                    </div>
                </div>
                <div className="row">
                    <div className="footer-copyright">
                        <span> © 2018 by Sarvam. All Rights Reserved.</span>
                    </div>
                </div>
            </div>
        </footer>
    ) : '';
};

export default Footer;