export const LOGIN_SUCCESS = 'user/login'
export const LOGOUT = 'user/logout'
export const MASTER_DATA = 'user/master'
export const UNIVERSITY = 'user/university'
export const SUBSCRIPTION = 'user/subscription'
export const JOBPOSITION = 'user/jobposition'
export const PROFILEPICTURE = 'user/profilepicture'

const initialState = {
    user: {},
    jobposition: {},
    subscription: {},
    university: {},
    master: {
        modulemaster: {
            yearsofWorkExpList: [],
            jobFunctionList: [],
            industryList: [],
            idCardTypeList: []
        },
        rmsCandidateAvailTypeList: [],
        rmsCandidateAvailNoticeList: [],
        rmsCandidateSourceList: [],
        rmsCandidateCareerLevelList: [],
        educationDegreeList: [],
        currencyList: [],
        maritalList: [],
        countryList: [],
        relationshipTypeList: [],
        studentIdentifierTypeList: [],
        programTypeList: [],
        languageList: [],
        salutationList: [],
        religionList: [],
        genderList: [],
        educationAttachmentTypeList: [],
        accreditationList: []
    },
    loggedin: false,
    defaultlanguage: 'en'
}
var loggedin = false;
export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                loggedin: true
            }
        case MASTER_DATA:
            return {
                ...state,
                master: action.master
            }
        case UNIVERSITY:
            return {
                ...state,
                university: action.university
            }
        case SUBSCRIPTION:
            return {
                ...state,
                subscription: action.subscription
            }
        case JOBPOSITION:
            return {
                ...state,
                jobposition: action.jobposition
            }
        case PROFILEPICTURE:
            return {
                ...state,
                profilePicture: action.profilePicture
            }
        case LOGOUT:
            return initialState;
        default:
            return state
    }
}
export const storeMaster = (res) => {
    return dispatch => {
        dispatch({
            type: MASTER_DATA,
            master: res
        })
    }
}
export const storeUser = (res) => {
    loggedin = true;
    return dispatch => {
        dispatch({
            type: LOGIN_SUCCESS,
            user: res
        })
    }
}
export const storeProfilePicture = (res) => {
    return dispatch => {
        dispatch({
            type: PROFILEPICTURE,
            profilePicture: res
        })
    }
}
export const storeSubscription = (res) => {
    return dispatch => {
        dispatch({
            type: SUBSCRIPTION,
            subscription: res
        })
    }
}
export const storeJobPosition = (res) => {
    return dispatch => {
        dispatch({
            type: JOBPOSITION,
            jobposition: res
        })
    }
}

export const removeUser = () => {
    loggedin = false;
    return dispatch => {
        dispatch({
            type: LOGOUT,
            user: {}
        })
    }
}

export const storeUniversity = (res) => {
    return dispatch => {
        dispatch({
            type: UNIVERSITY,
            university: res
        })
    }
}
export const removeUniversity = () => {
    return dispatch => {
        dispatch({
            type: UNIVERSITY,
            university: {}
        })
    }
}

export const isLoggedin = () => {

    return loggedin;
}