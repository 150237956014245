import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from '../pages/HomePage';
import ApplyPage from '../pages';
import UnderConstruction from '../pages/UnderConstruction';
import Footer from '../components/Footer/Footer';
import PrivateRoute from './PrivateRoute';
import InitialPage from '../pages/InitialPage';

class App extends React.Component {
    render() {
        return (
            <div className="layout-cnt">
                <Switch>
                    <Route exact path='/' component={InitialPage} />
                    <Route exact path="/home/:subid/:jobPosUID?" component={HomePage} />
                    <PrivateRoute path="/applypage" component={ApplyPage} />
                    <Route path="/underconstruction" component={UnderConstruction} />
                    <Route component={InitialPage} />
                </Switch>
                <Footer />
            </div>
        );
    }
}
export default App;