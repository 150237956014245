import { formDataConsole, getSignature, DEV_URL, devLog } from "../../_helper/helper.methods";
import * as Package from './package';
var ip = require("ip");

export const CoreList = (subscription) => {
    let formData = new FormData();
    let listName = 'Country,nationality,Currency,EducationDegree,Gender,Help,Language,Marital,RelationshipType,Religion,StudentContactType,StudentIdentifierType,Accreditation,ProgramType,MediaType,City,salutation,RMSCandidateAvailType,RMSCandidateAvailNotice,RMSCandidateCareerLevel';
    let signature;
    signature = listName;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', subscription);
    formData.append("ActionBy", '00000000-0000-0000-0000-000000000000');
    formData.append('DeviceIP', ip.address());
    formData.append('ListName', listName);

    let url = "/api/core/master/list";

    formDataConsole(formData, url);
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// core master list response", res)
            return res;
        })
        .catch((err) => { devLog(err) });
}

export const List = (user, subscription) => {
    let formData = new FormData();
    let listName = 'Years of Work Exp,Job Function,Industry,ID Card Type';

    let signature = subscription.toLowerCase() + listName.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', subscription);
    formData.append("ActionBy", "00000000-0000-0000-0000-000000000000");
    formData.append('DeviceIP', ip.address());
    formData.append('ListName', listName);
    formDataConsole(formData);
    let url;
    url = '/api/hrms/master/list';

    devLog('url', url)
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// master list response", res)
            return res;
        })
        .catch((err) => { devLog(err) });
}

export const UploadBlob = (props, file) => {
    let formData = new FormData();
    let signature = props.user.profileUID.toLowerCase() + props.user.profileUID.toLowerCase() + props.user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Subscription', props.subscription.subscription);
    formData.append('Signature', signature);
    formData.append("ActionBy", '00000000-0000-0000-0000-000000000000');
    formData.append('File', file);
    formData.append('DeviceIP', ip.address());
    formDataConsole(formData);
    let url = "/api/core/uploadtoblob"
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// upload to blob response", res)
            return res;
        })
}

export const AccessBlob = (user, blob) => {

    let signature = blob + user.profileUID.toLowerCase() + user.token;

    devLog('raw signature', signature)

    signature = getSignature(signature);

    let formData = new FormData();

    formData.append('BlobURL', blob);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", '00000000-0000-0000-0000-000000000000');
    formData.append('DeviceIP', ip.address());

    formDataConsole(formData, 'accessblob');
    let url = "/api/core/accessbloburl"
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// blob url response", res)
            return res;
        })
        .catch((err) => { devLog(err) });
}

export { Package };
