import { formDataConsole, getSignature, DEV_URL, devLog } from "../../_helper/helper.methods";
var ip = require("ip");

export const GetAppliedList = (props) => {
    const formData = new FormData();
    let signature = "" + props.user.profileUID.toLowerCase() + props.user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', props.subscription.subscription);
    formData.append("ActionBy", props.user.profileUID);
    formData.append('DeviceIP', ip.address());

    formDataConsole(formData);

    let url = '/api/hrms/rms/candidate/Applied/list';

    devLog('url', url);

    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// Applied list response", res)
            return res;
        })
        .catch((err) => { devLog(err) });
}

export const AutoSave = (props, tempJSON) => {
    const formData = new FormData();
    let signature = props.user.profileUID.toLowerCase() + props.user.profileUID.toLowerCase() + props.user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Subscription', props.subscription.subscription);
    formData.append('ProfileUID', props.user.profileUID);
    formData.append('Signature', signature);
    formData.append('tempJSON', tempJSON);
    formData.append('ActionBy', props.user.profileUID);
    formData.append('DeviceIP', ip.address());
    formDataConsole(formData);
    let url = "/api/hrms/rms/candidate/autosave";
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// candidate autosave response", res)
            return res;
        }).catch((err) => { devLog(err) });
}

export const Apply = (user, tempJSON) => {
    const formData = new FormData();
    let signature = user.profileUID.toLowerCase() + user.profileUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('subscription', user.subscription);
    formData.append('profileUID', user.profileUID);
    formData.append('Signature', signature);
    formData.append('tempJSON', tempJSON);
    formData.append('ActionBy', user.profileUID);
    formData.append('DeviceIP', ip.address());
    formDataConsole(formData);
    let url = "/api/hrms/rms/candidate/apply";
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// candidate apply response", res)
            return res;
        }).catch((err) => { devLog(err) });
}

export const Profile = (user) => {
    const formData = new FormData();
    let signature = user.profileUID.toLowerCase() + user.profileUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('subscription', user.subscription);
    formData.append('profileUID', user.profileUID);
    formData.append('Signature', signature);
    formData.append('ActionBy', user.profileUID);
    formData.append('DeviceIP', ip.address());
    formDataConsole(formData);
    let url = "/api/hrms/rms/candidate/profile";
    //return fetch(`/${DEV_URL}${url}`, {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// candidate profile response", res)
            return res;
        }).catch((err) => { devLog(err) });
}

