import React from 'react';
import { NavLink } from 'react-router-dom';

export const CustomHeaders = ({ pathname }) => (
    <header className="tabs-bar">
        <ul className="tabs-lists">
            <li><NavLink activeClassName='' to='/applypage/welcome' >Welcome</NavLink></li>
            <li><NavLink activeClassName='' to='/applypage/jobposition' >Job Position</NavLink></li>
            <li><NavLink activeClassName='' to='/applypage/jobapplied' >Applied List</NavLink></li>            
            <li><NavLink activeClassName='' to='/applypage/changepassword' >Change Password</NavLink></li>
        </ul>
    </header>
);