var encr = require('crypto-js');
//var moment = require('moment');
//export const DEV_URL = 'bravoerpapidev/';
export const DEV_URL = process.env.REACT_APP_API_URL;//'https://dev-ftp.azurewebsites.net/sarvamapi/';
export const DEV_LOG = process.env.REACT_APP_NODE_ENV;
export const formDataConsole = (formData, url) => {
    devLog('// API Request Body Data', url)
    for (var pair of formData.entries()) {
        devLog(pair[0] + ' : ' + pair[1]);
    }
    devLog('// End Request')
}

export const getSignature = (sig) => {
    var wordArray = encr.SHA1(sig);
    var words = wordArray.words;
    var sigBytes = wordArray.sigBytes;
    var hexChars = [];
    for (var i = 0; i < sigBytes; i++) {
        var bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
        hexChars.push((bite >>> 4).toString(16));
        hexChars.push((bite & 0x0f).toString(16));
    }
    return hexChars.join('').toUpperCase();
}

export const devLog = (...msgs) => {
    if (DEV_LOG === 'development') console.log(...msgs)
}
