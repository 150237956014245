import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import user from "./reducer.user";
import notification from './reducer.notification';
import { localeReducer as locale } from 'react-localize-redux';

export default combineReducers({
    routing: routerReducer,
    user,   
    notification,
    locale
})